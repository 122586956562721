
// export const myGetter = ( state ) => {
//  return state
// }

export const currentUser = ( state ) => {
  return state.user
}

export const userImage = ( state ) => {
    const w = 100;
    const h = 100;
    if (!state.user) return "/assets/img/user/avatar-m.png";
    if (!state.user.img.includes("avatar-m")) {
      let arrImg = state.user.img.split("/");
      if (arrImg.length < 4) return state.user.img;
      let userType = `w_${w},h_${h},c_thumb,g_faces`;
      arrImg.splice(arrImg.length - 2, 0, userType);
      return arrImg.join("/");
    } else {
      return state.user.img;
    }
}

export const getEntriesByTerm = ( state ) => ( term = '' ) => {

    if ( term.length === 0 ) return state.entries

    return state.entries.filter( entry => entry.text.toLowerCase().includes( term.toLocaleLowerCase() ) )
}

// id
export const getEntryById = ( state ) => ( id = '' ) => {

    const entry = state.entries.find( entry => entry.id === id )

    if ( !entry ) return

    return { ...entry } // TODO: prueben
}



