// export const myAction = ( state ) => {

// }
import Memoria from "@/helpers/buffer";

export const setBuffer = (state, message) => {
  try {
    const { offset, trans, values, len } = JSON.parse(message);
    if (!trans) return;
    if (!state.buffer) {
      state.buffer = new Memoria();
    }
    switch (trans) {
      case 100:
        for (let index = 0; index < len; index++) {
          state.buffer.c[offset + index] = values[index];
        }
        break;
      case 1:
        for (let x = 0; x < len; x++) {
          let word = Math.floor(x / 16);
          let n = x % 16;
          state.buffer.c[offset + x] = (values[word] >> +n) & 1;
        }
        break;
      case 3:
        for (let index = 0; index < len; index++) {
          state.buffer.r.setUint16((offset + index) * 2, values[index], true);
        }
        break;

      default:
        break;
    }
  } catch (error) {
    console.log(error);
  }
};

export const mqttConnect = (state) => {
  const idx = state.devices.map((e) => e.id).indexOf(state.selected);
  state.mqtt.conectar(
    state.credentials,
    state.devices[idx],
    (topic, message) => {
      const t = topic.split("/")[topic.split("/").length - 1];
      const serial = topic.split("/")[topic.split("/").length - 2];
      try {
        if (t == "alive") {
          //console.log(serial, "alive", message.toString());
          state.devices.forEach((d) => {
            if (d.serial == serial) {
              state.changed++;
              d.connected = true;
              d.message = message.toString();
              clearTimeout(d.timer);
              d.timer = setTimeout(() => {
                d.connected = false;
              }, 10000);
            }
          });
        }
        if (t == "response") {
          setBuffer(state, message.toString());
          state.bufferChanged++;
          //store.commit("devices/setTransactionId", null);
          //console.log(store.state.devices.transactionId)
        }
      } catch (error) {
        console.log(error);
      }
    }
  );
};

export const setDevices = (state, data) => {
  state.devices = data.devices;
  state.selected = data.selected;
  state.credentials = data.user.credentials;
  const idx = data.devices.map((e) => e.id).indexOf(state.selected);
  if (idx >= 0) {
    state.devices[idx].selected = true;
  } else {
    if (state.devices.length) state.selected = state.devices[0].id;
  }
  state.buffer = new Memoria();
  mqttConnect(state);
};

export const setSelected = (state, id) => {
  state.selected = id;
  state.buffer = new Memoria();
  mqttConnect(state);
  state.changed++;
};

export const setEntries = (state, entries) => {
  state.entries = [...state.entries, ...entries];
  state.isLoading = false;
};

export const updateDevices = (state, data) => {
  const idx = data.devices.map((e) => e.id).indexOf(state.selected);
  if (idx >= 0) {
    data.devices[idx].selected = true;
  } else {
    if (data.devices.length) state.selected = data.devices[0].id;
  }
  state.devices = data.devices;
  state.changed++;
  mqttConnect(state);
};

export const updateDevice = (state, device) => {
  const idx = state.devices.map((e) => e.id).indexOf(device.id);
  state.devices[idx] = device;
  state.changed++;
};

export const addDevice = (state, device) => {
  state.devices.unshift(device);
  state.changed++;
};

export const addEntry = (state, entry) => {
  state.entries.unshift(entry);
};

export const deleteEntry = (state, id) => {
  state.entries = state.entries.filter((entry) => entry.id !== id);
};

export const deleteDevice = (state, id) => {
  state.devices = state.devices.filter((device) => device.id !== id);
  if (state.selected == id) {
    if (state.devices.length) state.selected = state.devices[0].id;
  }
  state.changed++;
};

export const setVariables = (state, variables) => {
  state.variables = variables;
  state.changed++;
};

export const addVariable = (state, device) => {
  state.variables.push(device);
  state.changed++;
};

export const updateVariable = (state, variable) => {
  const idx = state.variables.map((e) => e.id).indexOf(variable.id);
  state.variables[idx] = variable;
  state.changed++;
};

export const deleteVariable = (state, id) => {
  state.variables = state.variables.filter((variable) => variable.id !== id);
  state.changed++;
};

export const updateTemplate = (state, template) => {
  state.template = template;
  state.changed++;
};

export const setMarkers = (state, markers) => {
  state.markers = markers;
};


export const setWeatherData = (state, weatherData) => {
  state.weatherData = weatherData;
};

export const setForecast = (state, forecast) => {
  state.forecast = forecast;
};
