<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Inicio</a></li>
      <li class="breadcrumb-item active">Instalaciones</li>
    </ol>
    <!-- END breadcrumb -->
    <!-- BEGIN page-header -->
    <h1 class="page-header">
      Instalaciones <small>Selecciona o añade un dispositivo</small>
    </h1>
    <!-- END page-header -->
    <!-- BEGIN #options -->
    <div id="options" class="mb-3">
      <div class="d-flex flex-wrap text-nowrap mb-n1">
        <a
          v-b-modal.modalAdd
          href="javascript:;"
          class="btn btn-primary me-1 mb-1"
          @click="inicializar"
          ><i class="fa fa-plus"></i> Añadir</a
        >
      </div>
    </div>
    <!-- #modal-dialog -->
    <b-modal id="modalAdd" cancel-variant="default" hide-footer>
      <template slot="modal-header">
        <h4 class="modal-title">Añadir dispositivo</h4>
        <a class="btn-close" @click="$bvModal.hide('modalAdd')"></a>
        <a class="btn-ok" @click="searchDevice"></a>
      </template>
      <div class="form-floating">
        <input
          type="text"
          class="form-control fs-15px"
          id="floatingInputValue"
          placeholder="xxxx-xxxx-xxxx-xxxx"
          v-model="serial"
          @keyup.enter="buscar"
        />
        <label
          for="floatingInputValue"
          class="d-flex align-items-center fs-13px"
          >Número de serie del dispositivo</label
        >
      </div>
      <small class="fs-12px text-gray-500-darker" v-show="buscando"
        ><i class="fas fa-spinner fa-spin"></i> Buscando...</small
      >
      <small class="fs-12px text-success" v-show="encontrado && !buscando"
        ><i class="fas fa-check"></i> Encontrado</small
      >
      <small class="fs-12px text-danger" v-show="noEncontrado && !buscando"
        ><i class="fas fa-face-frown"></i> No se ha encontrado el
        dispositivo...</small
      >
      <br />
      <b-button
        class="mt-4 me-2"
        variant="default"
        block
        @click="$bvModal.hide('modalAdd')"
        >Cerrar</b-button
      >
      <b-button class="mt-4" variant="primary" block @click="buscar"
        >Buscar</b-button
      >
    </b-modal>
    <!-- END #options -->
    <!-- BEGIN #gallery -->
    <div id="gallery" class="gallery d-flex flex-wrap">
      <!-- BEGIN image -->
      <div class="image" v-for="(device, index) in devicesArray" :key="index">
        <div class="image-inner">
          <a href="javascript:;" @click="seleccionar(device.id)">
            <div
              class="img"
              :style="{
                backgroundImage: 'url(' + imagen(device) + ')',
              }"
            ></div>
          </a>
          <p class="image-caption hover">
            <span :class="seleccionado(device)">Seleccionado</span>
          </p>
        </div>
        <div class="image-info">
          <h5 class="title">{{ device.name }}</h5>
          <div class="d-flex align-items-center mb-2">
            <div class="rating">
              <span class="me-4"><i :class="online(device)"></i> online</span>
              <span class="me-4 hover"
                ><i class="fa fa-edit" @click="editarDispositivo(index)"></i
              ></span>
              <span class="me-4 hover"
                ><i class="fa fa-trash" @click="borrar(device.id)"></i
              ></span>
            </div>
            <div class="ms-auto">
              <span class="me-4 hover"><i class="fa fa-camera"></i></span>
            </div>
          </div>
          <div class="desc">{{ device.description }}</div>
        </div>
      </div>
      <!-- END image -->
    </div>
    <!-- END #gallery -->
    <EditDevice :device="deviceToEdit" :user="currentUser" @update="refresh" />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { resizeImage } from "@/helpers/devices";
import EditDevice from "@/components/devices/EditDevice";

export default {
  name: "Devices",
  components: { EditDevice },
  data() {
    return {
      devicesArray: [],
      deviceToEdit: null,
      serial: "",
      buscando: false,
      encontrado: false,
      noEncontrado: false,
    };
  },
  mounted() {
    if (this.devices) this.devicesArray = this.devices;
    localStorage.setItem("screen", "devices");
  },
  computed: {
    ...mapGetters("devices", ["devices", "selected", "currentDevice", "changed"]),
    ...mapGetters("auth", ["currentUser"]),
  },
  methods: {
    ...mapMutations("devices", ["setSelected"]),
    ...mapActions("devices", ["searchDevice", "selectDevice", "deleteDevice"]),
    refresh() {
      this.devicesArray = {...this.devices};
    },
    editarDispositivo(index) {
      this.deviceToEdit = this.devices[index];
      this.$bvModal.show("editDevice");
    },
    imagen(device) {
      return resizeImage(device);
    },
    seleccionado(device) {
      return device.id == this.selected ? "selected" : "";
    },
    online(device) {
      return device.connected ? "fa fa-circle selected" : "fa fa-circle";
    },
    seleccionar(id) {
      this.selectDevice({ id });
    },
    buscar() {
      this.buscando = true;
      this.searchDevice({ serial: this.serial }).then((resp) => {
        console.log(resp);
        this.buscando = false;
        this.encontrado = resp;
        this.noEncontrado = !resp;
        if (resp) this.$bvModal.hide("modalAdd");
      });
    },
    borrar(id) {
      this.$bvModal
        .msgBoxConfirm("¿Estás seguro de eliminar la instalación de tu lista?", {
          title: 'Por Favor, Confirmar',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'SÍ',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
        .then((value) => {
          if (value) this.deleteDevice({user:this.currentUser, id}).then(()=>{
            this.refresh();
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    inicializar() {
      this.buscando = false;
      this.encontrado = false;
      this.noEncontrado = false;
    },
  },
  watch: {
    devices() {
      this.devicesArray = {...this.devices};
    },
    selected() {
      this.devicesArray = {...this.devices};
    },
    changed() {
      this.devicesArray = {...this.devices};
    },
  },
};
</script>

<style scoped>
.hover:hover {
  cursor: pointer;
  color: blue;
  font-size: 14px;
}
.selected {
  color: lime !important;
}
.online {
  color: lime !important;
}
</style>
