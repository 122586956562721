<template>
  <div>
    <!-- BEGIN login -->
    <div class="login login-v2 fw-bold">
      <!-- BEGIN login-cover -->
      <div class="login-cover">
        <div
          class="login-cover-img"
          v-bind:style="{ backgroundImage: 'url(' + bg.activeImg + ')' }"
        ></div>
        <div class="login-cover-bg"></div>
      </div>
      <!-- END login-cover -->

      <!-- BEGIN login-container -->
      <div class="login-container">
        <!-- BEGIN login-header -->
        <div class="login-header">
          <div class="brand">
            <div class="d-flex align-items-center">
              <img
                src="assets/img/logo-full.png"
                alt="logo"
                class="img-fluid"
                style="height: 64px"
              />
            </div>
            <small>Accede a tu panel de control</small>
          </div>
          <div class="icon">
            <i class="fa fa-lock"></i>
          </div>
        </div>
        <!-- END login-header -->

        <!-- BEGIN login-content -->
        <div class="login-content">
          <form v-on:submit="checkForm" method="GET">
            <div class="form-floating mb-20px">
              <input
                type="text"
                class="form-control fs-13px h-45px border-0"
                placeholder="Email Address"
                v-model="email"
              />
              <label
                for="emailAddress"
                class="d-flex align-items-center text-gray-600 fs-13px"
                >Email</label
              >
            </div>
            <div class="form-floating mb-20px">
              <input
                type="password"
                class="form-control fs-13px h-45px border-0"
                placeholder="Password"
                v-model="password"
              />
              <label
                for="emailAddress"
                class="d-flex align-items-center text-gray-600 fs-13px"
                >Password</label
              >
            </div>
            <div class="form-check mb-20px">
              <input
                class="form-check-input border-0"
                type="checkbox"
                value="1"
                v-model="remember"
              />
              <label
                class="form-check-label fs-13px text-gray-500"
                for="rememberMe"
              >
                Recuerdame
              </label>
            </div>
            <div class="mb-20px">
              <button
                type="submit"
                class="btn btn-success d-block w-100 h-45px btn-lg"
              >
                Acceder
              </button>
            </div>
            <div class="text-center text-gray-500">
              ¿No eres miembro aún? Haz click
              <router-link to="/register" class="text-gray-500">
                aquí
              </router-link>
              para registrarte.
            </div>
            <div class="text-center text-gray-500">
              ¿Has olvidado tu clave? 
              <router-link to="/forgot-password" class="text-gray-500">
                Recupérala aquí
              </router-link>
              
            </div>
          </form>
        </div>
        <!-- END login-content -->
      </div>
      <!-- END login-container -->
    </div>
    <!-- END login -->
    <notifications
      group="bottom-center"
      position="bottom center"
      :speed="500"
    />
  </div>
</template>

<script>
import AppOptions from "../config/AppOptions.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  created() {
    AppOptions.appEmpty = true;
  },
  beforeRouteLeave(to, from, next) {
    AppOptions.appEmpty = false;
    next();
  },
  data() {
    return {
      email: "",
      password: "",
      remember: false,
      bg: {
        activeImg: "/assets/img/login-bg/login-bg.jpg",
      },
    };
  },
  methods: {
    ...mapActions("auth", ["login"]),
    ...mapGetters("auth", ["currentUser"]),
    goToPath(){
      this.$router.push({ path: "/dashboard" });
    },
    async checkForm(e) {
      e.preventDefault();
      this.login({
        email: this.email,
        password: this.password,
        remember: this.remember,
      }).then((user) => {
        if (user.ok) {
          this.$notify({
            group: "bottom-center",
            title: `¡Operación exitosa!`,
            text: `Bienvenido ${user.name}`,
            type: "success",
          });
          setTimeout(() => {
            this.goToPath();
          }, 2000);
        } else {
          this.$notify({
            group: "bottom-center",
            title: `¡Algo fué mal en la operación!`,
            text: user.msg,
            type: "error",
          });
        }
      });
    },
  },
};
</script>
