<template>
  <div class="d-flex">
    <div
      v-if="!state"
      :class="'widget-icon rounded ' + colors[0] + ' text-white'"
      @click="clicked(0)"
    >
      <i :class="icons[0]">{{ text[0] }}</i>
    </div>
    <div
      v-else
      :class="'widget-icon rounded ' + colors[1] || colors[1] + ' text-white'"
      @click="clicked(1)"
    >
      <i :class="icons[1] || icons[0]">{{ text[1] || text[0] }}</i>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "IconPushButton",
  props: {
    colors: {
      type: Object,
      default: function () {
        return { 0: "bg-danger", 1: "bg-lime" };
      },
    },
    icons: {
      type: Object,
      default: function () {
        return { 0: "fa fa-power-off", 1: "fa fa-power-off" };
      },
    },
    text: {
      type: Object,
      default: function () {
        return { 0: "", 1: "" };
      },
    },
    tags: {
      type: Object,
      default: function () {
        return {
          0: "",
          1: "",
        };
      },
    },
    actions: {
      type: Object,
      default: function () {
        return {
          0: "reset",
          1: "set",
        };
      },
    },
  },
  data() {
    return {
      transactions: 0,
      state: 0,
    };
  },
  computed: {
    ...mapGetters("devices", ["currentDevice", "getValue", "bufferChanged"]),
  },
  methods: {
    ...mapActions("devices", ["setValue"]),
    clicked(index) {
      const action = this.actions[index] || this.actions[0];
      const register = this.tags[1] || this.tags[0];
      console.log(action, register);
      switch (action) {
        case "toggle":
          this.setValue({ register, values: this.state ? 0 : 1 });
          break;
        case "set":
          this.setValue({ register, values: 1 });
          break;
        case "reset":
          this.setValue({ register, values: 0 });
          break;
      }
      /* this.$store.dispatch(this.action, {
        variable: this.variable[state],
        value: this.getValue(this.variable[state]),
      }); */
    },
  },
  watch: {
    bufferChanged(nv) {
      this.state = this.getValue(this.tags[0]);
      this.transactions = nv;
    },
  },
};
</script>
