<template>
  <!-- BEGIN col-4 -->
  <div class="col-xl-4">
    <panel title="Localización" bodyClass="p-0">
      <GmapMap
        class="bg-gray-900"
        ref="mapRef"
        style="height: 295px"
        map-type-id="satellite"
        :options="options"
        :center="{ lat: 38, lng: -2 }"
      >
      </GmapMap>
    </panel>
  </div>
  <!-- END col-4 -->
</template>

<script>
import { gmapApi } from "vue2-google-maps";
import { mapGetters } from "vuex";

export default {
  name: "Map",
  computed: {
    ...mapGetters("devices", ["currentDevice"]),
    finca: function () {
      if (!this.currentDevice) return '<i class="fas fa-spinner fa-spin"></i>';
      return this.currentDevice.name
        ? this.currentDevice.name
        : '<i class="fas fa-spinner fa-spin"></i>';
    },
    google: gmapApi,
  },
  data() {
    return {
      options: {
        disableDefaultUI: true,
        mapId: "b93b21f674f7e4c9",
        mapTypeId: "satellite",
        scaleControl: true,
        heading: 0,
        tilt: 60,
        rotateControl: true,
        zoom: 15,
      },
      center: null,
    };
  },

  mounted() {
    this.setMapa();
  },
  methods: {
    setMapa() {
      if (this.currentDevice) {
        this.$refs.mapRef.$mapPromise.then((map) => {
          this.map = map;
          this.map.panTo(this.currentDevice.position);
          if (this.centro) this.centro.setMap(null);
          this.centro = new this.google.maps.Marker({
            icon: {
              url: "assets/img/maps/google-map-marker.png",
              labelOrigin: new this.google.maps.Point(30, 70),
              anchor: new this.google.maps.Point(30, 60),
            },
            label: {
              text: this.currentDevice.name,
              color: "#ffffff",
              fontWeight: "bold",
              fontSize: "16px",
            },
            draggable: true,
            position: this.currentDevice.position,
            map,
          });
        });
      }
    },
  },
  watch: {
    currentDevice: function (n, o) {
      if (n && o) {
        if (n.name != o.name) this.setMapa();
      } else if (n && !o) {
        this.setMapa();
      }
    },
  },
};
</script>

<style></style>
