<template>
  <div>
    <!-- BEGIN login -->
    <div class="login login-v2 fw-bold">
      <!-- BEGIN login-cover -->
      <div class="login-cover">
        <div
          class="login-cover-img"
          v-bind:style="{ backgroundImage: 'url(' + bg.activeImg + ')' }"
        ></div>
        <div class="login-cover-bg"></div>
      </div>
      <!-- END login-cover -->

      <!-- BEGIN login-container -->
      <div class="login-container">
        <!-- BEGIN login-header -->
        <div class="login-header">
          <div class="brand">
            <div class="d-flex align-items-center">
              <img
                src="assets/img/logo-full.png"
                alt="logo"
                class="img-fluid"
                style="height: 64px"
              />
            </div>
            <small>¿Olvidaste tu contraseña?</small>
          </div>
          <div class="icon">
            <i class="fa fa-face-grin-beam-sweat"></i>
          </div>
        </div>
        <!-- END login-header -->

        <!-- BEGIN login-content -->
        <div class="login-content">
          <form v-on:submit="checkForm" method="GET">
            <div class="form-floating mb-20px">
              <input
                type="text"
                class="form-control fs-13px h-45px border-0"
                placeholder="Email Address"
                v-model="email"
              />
              <label
                for="emailAddress"
                class="d-flex align-items-center text-gray-600 fs-13px"
                >Email</label
              >
            </div>
            <div class="mb-20px">
              <button
                type="submit"
                class="btn btn-primary d-block w-100 h-45px btn-lg"
              >
                Enviar
              </button>
            </div>
            <div class="text-gray-500">
              ¿Recordaste tu clave? Haz click
              <router-link to="/" class="text-gray-500">
                aquí
              </router-link>
              para acceder.
            </div>
          </form>
        </div>
        <!-- END login-content -->
      </div>
      <!-- END login-container -->
    </div>
    <!-- END login -->
    <notifications
      group="bottom-center"
      position="bottom center"
      :speed="500"
    />
  </div>
</template>

<script>
import AppOptions from "../config/AppOptions.vue";
import { mapActions } from "vuex";

export default {
  created() {
    AppOptions.appEmpty = true;
  },
  beforeRouteLeave(to, from, next) {
    AppOptions.appEmpty = false;
    next();
  },
  data() {
    return {
      email: "",
      bg: {
        activeImg: "/assets/img/login-bg/login-bg.jpg",
      },
    };
  },
  methods: {
    ...mapActions("auth", ["reset"]),
    goToPath(){
      this.$router.push({ path: "/dashboard" });
    },
    async checkForm(e) {
      e.preventDefault();
      this.reset({
        email: this.email,
      }).then((user) => {
        if (user.ok) {
          this.$notify({
            group: "bottom-center",
            title: `¡Operación exitosa!`,
            text: `Se ha enviado un correo con las instrucciones para recuperar tu contraseña.`,
            type: "success",
          });
          setTimeout(() => {
            this.goToPath();
          }, 2000);
        } else {
          this.$notify({
            group: "bottom-center",
            title: `¡Algo fué mal en la operación!`,
            text: user.msg,
            type: "error",
          });
        }
      });
    },
  },
};
</script>
