export const login = async ({ commit }, { email, password, remember }) => {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.VUE_APP_AUTHDOMAIN}/api/auth`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, password, remember }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.ok) {
          commit("setUser", data);
          const nUser = {
            id: data.uid,
            name: data.name,
            email: data.email,
            token: data.token,
          };
          localStorage.setItem("user", JSON.stringify(nUser));
        }
        resolve(data);
      })
      .catch((error) => {
        console.log(error);
        commit("setUser", null);
        reject({ ok: false, msg: "Algo ha ido mal en la operación" });
      });
  });
};

export const register = ({ commit }, user) => {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.VUE_APP_AUTHDOMAIN}/api/auth/new`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ...user, domain: process.env.VUE_APP_DOMAIN }),
    })
      .then((data) => data.json())
      .then((json) => {
        commit("setUser", null);
        resolve(json);
      })
      .catch((error) => {
        console.log(error);
        commit("setUser", null);
        reject({ ok: false, msg: "Algo ha ido mal en la operación" });
      });
  });
};

export const verifyAcount = ({ commit }, token) => {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.VUE_APP_AUTHDOMAIN}/api/auth/verify`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(token),
    })
      .then((data) => data.json())
      .then((json) => {
        commit("setUser", null);
        resolve(json);
      })
      .catch((error) => {
        console.log(error);
        commit("setUser", null);
        reject({ ok: false, msg: "Algo ha ido mal en la operación" });
      });
  });
};

export const reset = ({ commit }, email) => {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.VUE_APP_AUTHDOMAIN}/api/auth/forgot`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ...email, domain: process.env.VUE_APP_DOMAIN }),
    })
      .then((data) => data.json())
      .then((json) => {
        commit("setUser", null);
        resolve(json);
      })
      .catch((error) => {
        console.log(error);
        commit("setUser", null);
        reject({ ok: false, msg: "Algo ha ido mal en la operación" });
      });
  });
};

export const recovery = ({ commit }, {token, password}) => {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.VUE_APP_AUTHDOMAIN}/api/auth/recovery`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token, password }),
    })
      .then((data) => data.json())
      .then((json) => {
        commit("setUser", null);
        resolve(json);
      })
      .catch((error) => {
        console.log(error);
        commit("setUser", null);
        reject({ ok: false, msg: "Algo ha ido mal en la operación" });
      });
  });
};

export const setUser = async ({ commit }, user) => {
  commit("setUser", { ...user });
};

/*export const createEntry = async ({ commit }, entry) => {
  const { date, picture, text } = entry;
  const output = { date, picture, text };
  const { data } = await authApi.post(`/entries.json`, output);

  output.id = data.name;
  commit("addEntry", { ...output });
  return output.id
};

export const deleteEntry = async ({ commit }, id) => {
  await authApi.delete(`/entries/${id}.json`);
  commit("deleteEntry", id )
  
} */
