<template>
  <div class="col-xl-4 col-lg-4">
    <!-- BEGIN card -->
    <div class="card border-0 mb-3">
      <div class="card-body">
        <div class="mb-3">
          <b>EL TIEMPO</b>
        </div>
      </div>
      <div class="panel-body p-0">
        <div style="height: 270px">
          <div class="px-2">
            <div class="text-center py-2">
              <img
                :src="weatherImg"
                alt="weather"
                style="width: 120px; height: 120px"
              />
              <h2>{{ temp }}ºC</h2>
              <h4>Humedad: {{ humidity }}%</h4>
              <h4>
                Viento: {{ winSpeed }} m/s ({{ winDeg }}º)
                <i
                  class="fa fa-down-long"
                  :style="'transform: rotate(' + winDeg + 'deg);'"
                ></i>
              </h4>
            </div>
            <!-- <div class="d-flex justify-content-center p-2">
              <div v-for="(dia, index) in forecast" :key="index" class="">
                <h6 class="text-center ps-12px">{{ dia.date }}</h6>
                <img
                  class="mx-auto d-block"
                  :src="
                    'https://openweathermap.org/img/wn/' + dia.icon + '@2x.png'
                  "
                  :alt="dia.iconAlt"
                />
                <h5 class="text-center">
                  {{ dia.temp.toFixed(0) }}<small>ºC</small>
                </h5>
                <h6 class="text-center">
                  {{ dia.humidity.toFixed(0) }}<small>%</small>
                </h6>
                <h6 class="text-center">
                  {{ dia.wind.toFixed(1) }}<small>m/s</small>
                </h6>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- END card -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Weather",
  data() {
    return {
      weatherStation: "No disponible",
      temp: "NA",
      humidity: "NA",
      winSpeed: "NA",
      winDeg: "NA",
      weatherImg: "https://openweathermap.org/img/wn/01d@2x.png",
      forecast: [],
    };
  },
  mounted() {
    this.getWeather();
  },
  methods: {
    getWeather() {
      if (!this.getWeatherData) return;
      this.temp = this.getWeatherData.main.temp.toFixed(1);
      this.weatherStation = this.getWeatherData.name;
      this.humidity = this.getWeatherData.main.humidity;
      this.winSpeed = this.getWeatherData.wind.speed;
      this.winDeg = this.getWeatherData.wind.deg;
      this.weatherImg =
        "https://openweathermap.org/img/wn/" +
        this.getWeatherData.weather[0].icon +
        "@2x.png";
    },
  },
  computed: {
    ...mapGetters("devices", ["getWeatherData", "getForecast"]),
  },
  watch: {
    getWeatherData: {
      handler: function () {
        this.getWeather();
      },
      deep: true,
    },
    getForecast: {
      handler: function () {
        if (!this.getForecast) return;
        this.forecast = this.getForecast;
      },
      deep: true,
    },
  },
};
</script>

<style></style>

