<script>
const sidebarMenu = [
  { path: '/dashboard', icon: 'fa fa-cloud', title: 'Panel de control', role:'all' },
  { path: '/widget-controls', icon: 'fa fa-box', title: 'Widgets', role:'ADMIN' },
  { path: '/devices', icon: 'fa fa-dice-d6', title: 'Instalaciones', role:'all' },
  { path: '/map', icon: 'fa fa-earth-europa', title: 'Mapa', role:'all' },
  { path: '/map-controls', icon: 'fa fa-map-marked-alt', title: 'Controles de mapa', role:'ADMIN' },
  { path: '/mapbox', icon: 'fa fa-earth-europa', title: 'Mapa 2', role:'ADMIN' },
  { path: '/historical', icon: 'fa fa-chart-line', title: 'Histórico', role:'all' },
  { path: '/variables', icon: 'fa fa-flag', title: 'Variables', role:'ADMIN' },
  /* { path: '/menu', icon: 'fa fa-align-left', title: 'Menu Level',
    children: [
      { path: '/menu/menu-1-1', title: 'Menu 1.1',
        children: [
          { path: '/menu/menu-1-1/menu-2-1', title: 'Menu 2.1',
            children: [
              { path: '/menu/menu-1-1/menu-2-1/menu-3-1', title: 'Menu 3.1' },
              { path: '/menu/menu-1-1/menu-2-1/menu-3-2', title: 'Menu 3.2' }
            ]
          },
          { path: '/menu/menu-1-1/menu-2-2', title: 'Menu 2.2' },
          { path: '/menu/menu-1-1/menu-2-3', title: 'Menu 2.3' },
        ]
      },
      { path: '/menu/menu-1-2', title: 'Menu 1.2' },
      { path: '/menu/menu-1-3', title: 'Menu 1.3' },
    ]
  }, */
]

export default sidebarMenu;
</script>
