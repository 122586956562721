<template>
  <div>
    <!-- BEGIN login -->
    <div class="login login-v2 fw-bold">
      <!-- BEGIN login-cover -->
      <div class="login-cover">
        <div
          class="login-cover-img"
          v-bind:style="{ backgroundImage: 'url(' + bg.activeImg + ')' }"
        ></div>
        <div class="login-cover-bg"></div>
      </div>
      <!-- END login-cover -->

      <!-- BEGIN login-container -->
      <div class="login-container">
        <!-- BEGIN login-header -->
        <div class="login-header">
          <div class="brand">
            <div class="d-flex align-items-center">
              <img
                src="assets/img/logo-full.png"
                alt="logo"
                class="img-fluid"
                style="height: 64px"
              />
            </div>
            <small>¿Olvidaste tu contraseña?</small>
          </div>
          <div class="icon">
            <i class="fa fa-lock"></i>
          </div>
        </div>
        <!-- END login-header -->

        <!-- BEGIN login-content -->
        <div class="login-content">
          <form v-on:submit="checkForm" method="GET">
            <div class="form-floating mb-20px">
              <label class="mb-2 text-muted"
                >Password <span class="text-danger">*</span></label
              >
              <b-form-input
                v-model="pwd1"
                type="password"
                class="form-control fs-13px"
                placeholder="Password"
                :state="good_password_1"
              ></b-form-input>
            </div>
            <div class="form-floating mb-20px">
              <label class="mb-2 text-muted"
                >Repetir Password <span class="text-danger">*</span></label
              >
              <b-form-input
                v-model="pwd2"
                type="password"
                class="form-control fs-13px"
                placeholder="Password"
                :state="good_password_2"
              ></b-form-input>
            </div>
            <div class="mb-20px">
              <button
                type="submit"
                class="btn btn-primary d-block w-100 h-45px btn-lg"
                :disabled="!good_password_2"
              >
                Enviar
              </button>
            </div>
            <div class="text-gray-500">
              ¿Recordaste tu clave? accede
              <router-link to="/" class="text-gray-500"> aquí </router-link>
            </div>
          </form>
        </div>
        <!-- END login-content -->
      </div>
      <!-- END login-container -->
    </div>
    <!-- END login -->
    <notifications
      group="bottom-center"
      position="bottom center"
      :speed="500"
    />
  </div>
</template>

<script>
import AppOptions from "../config/AppOptions.vue";
import { mapActions } from "vuex";

export default {
  created() {
    AppOptions.appEmpty = true;
  },
  beforeRouteLeave(to, from, next) {
    AppOptions.appEmpty = false;
    next();
  },
  data() {
    return {
      pwd1: "",
      pwd2: "",
      bg: {
        activeImg: "/assets/img/login-bg/login-bg.jpg",
      },
    };
  },
  methods: {
    ...mapActions("auth", ["recovery"]),
    goToPath() {
      this.$router.push({ path: "/dashboard" });
    },
    goodPassword: (password) => {
      if (password.length == 0) return false;
      //a custom validator!
      return (
        password.length >= 6 &&
        /[a-z]/.test(password) &&
        /[A-Z]/.test(password) &&
        /[0-9]/.test(password)
      );
    },
    async checkForm(e) {
      e.preventDefault();
      const { token } = this.$router.currentRoute.query;
      this.recovery({
        password: this.pwd1,
        token,
      }).then((user) => {
        if (user.ok) {
          this.$notify({
            group: "bottom-center",
            title: `¡Operación exitosa!`,
            text: `Se ha enviado un correo ${this.email}`,
            type: "success",
          });
          setTimeout(() => {
            this.goToPath();
          }, 2000);
        } else {
          this.$notify({
            group: "bottom-center",
            title: `¡Algo fué mal en la operación!`,
            text: user.msg,
            type: "error",
          });
        }
      });
    },
  },
  computed: {
    good_password_1() {
      return this.goodPassword(this.pwd1);
    },
    good_password_2() {
      return this.goodPassword(this.pwd1) && this.goodPassword(this.pwd2);
    },
  },
};
</script>
