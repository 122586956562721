import mqtt from "mqtt";

class MQTT {
  constructor() {
    this.connectUrl = "wss://smart-actor.cloudmqtt.com:443/mqtt";
  }

  getOptions() {
    return {
      host: "smart-actor.cloudmqtt.com",
      port: 443,
      endpoint: "/mqtt",
      protocolId: "MQTT",
      protocolVersion: 4,
      clean: true,
      connectTimeout: 5000,
      reconnectPeriod: 5000,
      // Certification Information
      clientId:
        this.credentials.user + "_" + Math.floor(Math.random() * 1000000 + 1),
      username: this.credentials.user,
      password: this.credentials.password,
    };
  }

  conectar(credentials, device, onMessage) {
    this.credentials = credentials;
    this.device = device;
    this.onMessage = onMessage;
    this.client = { connected: false };
    if (this.client.connected) this.desconectar();

    try {
      this.client = mqtt.connect(this.connectUrl, this.getOptions());
      //MQTT CONNECTION SUCCESS
      this.client.on("connect", () => {
        console.log("Connection succeeded!");
        this.subscribe();
        this.lecturas();
      });

      this.client.on("message", this.onMessage);

      this.client.on("error", (error) => {
        console.log("Connection failed", error);
      });

      this.client.on("reconnect", () => {
        console.log("reconnecting:");
      });

      this.client.on("close", () => {
        console.log("Connection disconected");
      });

      this.client.on("disconnect", (packet) => {
        console.log("Connection disconected", packet);
      });

      this.client.on("end", () => {
        console.log("connection end");
      });
    } catch (error) {
      console.log(error);
      return;
    }
  }

  //SDATA SUBSCRIBE
  subscribe() {
    const topics = ["device/+/response", "device/+/alive"];
    this.unsubscribe(topics);
    this.client.subscribe(topics, { qos: 0 }, (err) => {
      if (err) {
        console.log("Error in responseSubscription", err);
        return;
      }
      console.log("response subscription Success");
    });
  }

  //SDATA UNSUBSCRIBE
  unsubscribe(topics) {
    this.client.unsubscribe(topics, { qos: 0 }, (err) => {
      if (err) {
        console.log("Error in responseSubscription", err);
        return;
      }
      console.log("response subscription Success");
    });
  }

  desconectar() {
    if (this.client.connected) {
      try {
        this.client.end();
        this.client = {
          connected: false,
        };
        console.log("Successfully disconnected!");
      } catch (error) {
        console.log("Disconnect failed", error.toString());
      }
    }
  }

  publicar(client, serial, pass, transaction) {
    try {
      const topic = "device/" + serial + "/" + pass + "/transaction";
      client.publish(topic, JSON.stringify(transaction));
    } catch (error) {
      console.log(error);
    }
  }

  publish(registro, values, buffer) {
    try {
      const { serial, password } = this.device;
      const topic = "device/" + serial + "/" + password + "/transaction";
      console.log(registro.address, values);
      const transaction = this.makeTransaction(
        registro.address,
        registro.dataType,
        values,
        buffer
      );
      console.log(topic, transaction);
      this.client.publish(topic, JSON.stringify(transaction));
    } catch (error) {
      console.log(error);
    }
  }

  lecturas() {
    if (this.transactions) {
      this.transactions.forEach((transaction) => {
        clearInterval(transaction.timer);
      });
    }
    this.transactions = [];
    const requests = this.getRequest(this.device.request);

    requests.forEach((request) => {
      const transaction = {
        request,
        timer: setInterval(
          this.publicar,
          1000,
          this.client,
          this.device.serial,
          this.device.password,
          request
        ),
      };
      this.transactions.push(transaction);
    });
  }

  getRequest = (raw = "") => {
    if (!isNaN(parseFloat(raw)) && isFinite(raw)) return [];
    const peticiones = raw.split(".").map((p) => {
      return p.split("_");
    });
    const fcs = [];
    peticiones.forEach((p) => {
      switch (p[0].toLowerCase()) {
        case "r":
          fcs.push({
            trans: 3,
            offset: parseInt(p[1]) || 0,
            len: parseInt(p[2]) || 0,
          });
          break;
        case "c":
          fcs.push({
            trans: 1,
            offset: parseInt(p[1]) || 0,
            len: parseInt(p[2]) || 0,
          });
          break;
      }
    });

    return fcs;
  };

  makeTransaction = (raw, type, value, buffer) => {
    if (!isNaN(parseFloat(raw)) && isFinite(raw)) return;
    const area = raw.split("_")[0];
    let offset = parseInt(raw.split("_")[1]);
    const bit = parseInt(raw.split("_")[2]);
    if (!area ) return;
    console.log(type, buffer.getUint(offset))
    switch (area.toLowerCase()) {
      case "r":
        switch (type) {
          case "bool":
            return buffer.setUint(
              offset,
              buffer.bit_write(buffer.getUint(offset), bit, value)
            );
          case "int":
            return buffer.setInt( offset, value)
          case "uint":
            return buffer.setUint( offset, value)
          case "long":
            return buffer.setLong( offset, value)
          case "ulong":
            return buffer.setULong( offset, value);
          case "float":
            return buffer.setFloat( offset, value);
          default:
            return null;
        }
      case "c":
        return buffer.writeBit(offset, value);
    }
    return;
  };

  /* makeTransaction = (raw, type, values, buffer) => {
    if (!isNaN(parseFloat(raw)) && isFinite(raw)) return;
    const area = raw.split("_")[0];
    let offset = raw.split("_")[1];
    let bit = raw.split("_")[2];
    if (!area || !offset) return;
    switch (area.toLowerCase()) {
      case "r":
        switch (type) {
          case "bool":
            return buffer.setUint( offset, buffer.bit_set(buffer.getUint(offset), bit));
          case "int":
          case "uint":
            return {
              trans: 6,
              offset: offset,
              values,
            };
          case "long":
            return {
              trans: 6,
              offset: offset,
              values,
            };
          case "ulong":
            return {
              trans: 6,
              offset: offset,
              values,
            };
          case "float":
            return {
              trans: 6,
              offset: offset,
              values,
            };
          default:
            return null;
        }
      case "c":
        return {
          trans: 5,
          offset: offset,
          values,
        };
    }
    return;
  }; */

  isConnected() {
    return this.client.connected;
  }
}

export default MQTT;
