<template>
  <div class="col-xl-8">
    <div class="widget-chart with-sidebar inverse-mode">
      <div class="widget-chart-content bg-gray-800">
        <h4 class="chart-title">
          Análisis de energía
          <small>Potencia total y potencia generada </small>
        </h4>
        <div
          class="widget-chart-full-width dark-mode overflow-hidden"
          style="height: 260px"
        >
          <vn-stacked-area
            :model="areaChart.data"
            yFormat=",.0f"
            :xFormat="formatDate"
            :clipEdge="areaChart.clipEdge"
            :rightAlignYAxis="areaChart.rightAlignYAxis"
            :showControls="areaChart.showControls"
          ></vn-stacked-area>
        </div>
      </div>
      <div class="widget-chart-sidebar bg-gray-900">
        <div class="chart-number">
          1,225,729
          <small>Energía generada total</small>
        </div>
        <div class="flex-grow-1 d-flex align-items-center">
          <div class="nvd3-inverse-mode" style="height: 180px">
            <vn-pie
              :model="donutChart.data"
              :donut="donutChart.donut"
              text-field="label"
              value-field="value"
              :show-tooltip-percent="true"
              :show-legend="false"
            ></vn-pie>
          </div>
        </div>
        <ul class="chart-legend fs-11px">
          <li>
            <i class="fa fa-circle fa-fw text-blue fs-9px me-5px t-minus-1"></i>
            34.0% <span>Red</span>
          </li>
          <li>
            <i class="fa fa-circle fa-fw text-yellow fs-9px me-5px t-minus-1"></i>
            66.0% <span>Generada</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import AppOptions from "@/config/AppOptions.vue";

export default {
  name: "EnergyChart",
  data() {
    function handleGetDate(minusDate) {
      var d = new Date();
      d = d.setHours(d.getHours() - minusDate);
      return d;
    }

    return {
      areaChart: {
        rightAlignYAxis: false,
        showControls: false,
        clipEdge: false,
        data: [
          {
            key: "Potencia generada",
            color: AppOptions.color.yellow,
            values: [
              { x: handleGetDate(77), y: 13 },
              { x: handleGetDate(76), y: 13 },
              { x: handleGetDate(75), y: 6 },
              { x: handleGetDate(73), y: 6 },
              { x: handleGetDate(72), y: 6 },
              { x: handleGetDate(71), y: 5 },
              { x: handleGetDate(70), y: 5 },
              { x: handleGetDate(69), y: 5 },
              { x: handleGetDate(68), y: 6 },
              { x: handleGetDate(67), y: 7 },
              { x: handleGetDate(66), y: 6 },
              { x: handleGetDate(65), y: 9 },
              { x: handleGetDate(64), y: 9 },
              { x: handleGetDate(63), y: 8 },
              { x: handleGetDate(62), y: 10 },
              { x: handleGetDate(61), y: 10 },
              { x: handleGetDate(60), y: 10 },
              { x: handleGetDate(59), y: 10 },
              { x: handleGetDate(58), y: 9 },
              { x: handleGetDate(57), y: 9 },
              { x: handleGetDate(56), y: 10 },
              { x: handleGetDate(55), y: 9 },
              { x: handleGetDate(54), y: 9 },
              { x: handleGetDate(53), y: 8 },
              { x: handleGetDate(52), y: 8 },
              { x: handleGetDate(51), y: 8 },
              { x: handleGetDate(50), y: 8 },
              { x: handleGetDate(49), y: 8 },
              { x: handleGetDate(48), y: 7 },
              { x: handleGetDate(47), y: 7 },
              { x: handleGetDate(46), y: 6 },
              { x: handleGetDate(45), y: 6 },
              { x: handleGetDate(44), y: 6 },
              { x: handleGetDate(43), y: 6 },
              { x: handleGetDate(42), y: 5 },
              { x: handleGetDate(41), y: 5 },
              { x: handleGetDate(40), y: 4 },
              { x: handleGetDate(39), y: 4 },
              { x: handleGetDate(38), y: 5 },
              { x: handleGetDate(37), y: 5 },
              { x: handleGetDate(36), y: 5 },
              { x: handleGetDate(35), y: 7 },
              { x: handleGetDate(34), y: 7 },
              { x: handleGetDate(33), y: 7 },
              { x: handleGetDate(32), y: 10 },
              { x: handleGetDate(31), y: 9 },
              { x: handleGetDate(30), y: 9 },
              { x: handleGetDate(29), y: 10 },
              { x: handleGetDate(28), y: 11 },
              { x: handleGetDate(27), y: 11 },
              { x: handleGetDate(26), y: 8 },
              { x: handleGetDate(25), y: 8 },
              { x: handleGetDate(24), y: 7 },
              { x: handleGetDate(23), y: 8 },
              { x: handleGetDate(22), y: 9 },
              { x: handleGetDate(21), y: 8 },
              { x: handleGetDate(20), y: 9 },
              { x: handleGetDate(19), y: 10 },
              { x: handleGetDate(18), y: 9 },
              { x: handleGetDate(17), y: 10 },
              { x: handleGetDate(16), y: 16 },
              { x: handleGetDate(15), y: 17 },
              { x: handleGetDate(14), y: 16 },
              { x: handleGetDate(13), y: 17 },
              { x: handleGetDate(12), y: 16 },
              { x: handleGetDate(11), y: 15 },
              { x: handleGetDate(10), y: 14 },
              { x: handleGetDate(9), y: 24 },
              { x: handleGetDate(8), y: 18 },
              { x: handleGetDate(7), y: 15 },
              { x: handleGetDate(6), y: 14 },
              { x: handleGetDate(5), y: 16 },
              { x: handleGetDate(4), y: 16 },
              { x: handleGetDate(3), y: 17 },
              { x: handleGetDate(2), y: 7 },
              { x: handleGetDate(1), y: 7 },
              { x: handleGetDate(0), y: 7 },
            ],
          },
          {
            key: "Potencia total",
            color: AppOptions.color.blue,
            values: [
              { x: handleGetDate(77), y: 14 },
              { x: handleGetDate(76), y: 13 },
              { x: handleGetDate(75), y: 15 },
              { x: handleGetDate(73), y: 14 },
              { x: handleGetDate(72), y: 13 },
              { x: handleGetDate(71), y: 15 },
              { x: handleGetDate(70), y: 16 },
              { x: handleGetDate(69), y: 16 },
              { x: handleGetDate(68), y: 14 },
              { x: handleGetDate(67), y: 14 },
              { x: handleGetDate(66), y: 13 },
              { x: handleGetDate(65), y: 12 },
              { x: handleGetDate(64), y: 13 },
              { x: handleGetDate(63), y: 13 },
              { x: handleGetDate(62), y: 15 },
              { x: handleGetDate(61), y: 16 },
              { x: handleGetDate(60), y: 16 },
              { x: handleGetDate(59), y: 17 },
              { x: handleGetDate(58), y: 17 },
              { x: handleGetDate(57), y: 18 },
              { x: handleGetDate(56), y: 15 },
              { x: handleGetDate(55), y: 15 },
              { x: handleGetDate(54), y: 15 },
              { x: handleGetDate(53), y: 19 },
              { x: handleGetDate(52), y: 19 },
              { x: handleGetDate(51), y: 18 },
              { x: handleGetDate(50), y: 18 },
              { x: handleGetDate(49), y: 17 },
              { x: handleGetDate(48), y: 16 },
              { x: handleGetDate(47), y: 18 },
              { x: handleGetDate(46), y: 18 },
              { x: handleGetDate(45), y: 18 },
              { x: handleGetDate(44), y: 16 },
              { x: handleGetDate(43), y: 14 },
              { x: handleGetDate(42), y: 14 },
              { x: handleGetDate(41), y: 13 },
              { x: handleGetDate(40), y: 14 },
              { x: handleGetDate(39), y: 13 },
              { x: handleGetDate(38), y: 10 },
              { x: handleGetDate(37), y: 9 },
              { x: handleGetDate(36), y: 10 },
              { x: handleGetDate(35), y: 11 },
              { x: handleGetDate(34), y: 11 },
              { x: handleGetDate(33), y: 11 },
              { x: handleGetDate(32), y: 10 },
              { x: handleGetDate(31), y: 9 },
              { x: handleGetDate(30), y: 10 },
              { x: handleGetDate(29), y: 13 },
              { x: handleGetDate(28), y: 14 },
              { x: handleGetDate(27), y: 14 },
              { x: handleGetDate(26), y: 13 },
              { x: handleGetDate(25), y: 12 },
              { x: handleGetDate(24), y: 11 },
              { x: handleGetDate(23), y: 13 },
              { x: handleGetDate(22), y: 13 },
              { x: handleGetDate(21), y: 13 },
              { x: handleGetDate(20), y: 13 },
              { x: handleGetDate(19), y: 14 },
              { x: handleGetDate(18), y: 13 },
              { x: handleGetDate(17), y: 13 },
              { x: handleGetDate(16), y: 19 },
              { x: handleGetDate(15), y: 21 },
              { x: handleGetDate(14), y: 22 },
              { x: handleGetDate(13), y: 25 },
              { x: handleGetDate(12), y: 24 },
              { x: handleGetDate(11), y: 24 },
              { x: handleGetDate(10), y: 22 },
              { x: handleGetDate(9), y: 16 },
              { x: handleGetDate(8), y: 15 },
              { x: handleGetDate(7), y: 12 },
              { x: handleGetDate(6), y: 12 },
              { x: handleGetDate(5), y: 15 },
              { x: handleGetDate(4), y: 15 },
              { x: handleGetDate(3), y: 15 },
              { x: handleGetDate(2), y: 18 },
              { x: handleGetDate(2), y: 18 },
              { x: handleGetDate(0), y: 17 },
            ],
          },
        ],
      },
      donutChart: {
        donut: true,
        data: [
          {
            label: "Red",
            value: 404466,
            color: AppOptions.color.blue,
          },
          {
            label: "Solar",
            value: 816747,
            color: AppOptions.color.yellow,
          },
        ],
      }
      
    };
  },
  methods: {
    formatDate(d) {
      var monthsName = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      d = new Date(d);
      d = monthsName[d.getMonth()] + " " + d.getDate();
      return d;
    },
  },
};
</script>

<style></style>
