<template>
  <div class="menu">
    <div
      class="menu-profile"
      :style="{
        backgroundImage: 'url(' + activeImg + ')',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }"
    >
      <a href="javascript:;" class="menu-profile-link" v-on:click="expand()">
        <div class="with-shadow"></div>
        <div
          class="
            menu-profile-image menu-profile-image-icon
            bg-gray-900
            text-gray-600
          "
        >
          <img :src="image" alt="user" />
        </div>
        <div class="menu-profile-info">
          <div class="d-flex align-items-center">
            <div class="flex-grow-1">
              {{ user.name }}
            </div>
            <div class="menu-caret ms-auto"></div>
          </div>
          <small>{{ user.email }}</small>
        </div>
      </a>
    </div>
    <div
      id="appSidebarProfileMenu"
      class="collapse"
      v-bind:class="{
        'd-block':
          this.stat == 'expand' && !this.appOptions.pageSidebarMinified,
        'd-none': this.stat == 'collapse',
      }"
    >
      <div class="menu-item pt-5px">
        <a href="javascript:;" class="menu-link">
          <div class="menu-icon"><i class="fa fa-cog"></i></div>
          <div class="menu-text">Settings</div>
        </a>
      </div>
      <div class="menu-divider m-0"></div>
    </div>
  </div>
</template>

<script>
import AppOptions from "../../config/AppOptions.vue";
import { mapGetters } from "vuex";

export default {
  name: "SidebarNavProfile",
  data() {
    return {
      user: {name:'',email:''},
      image:'/assets/img/user/avatar-m.png',
      stat: "",
      appOptions: AppOptions,
      activeImg: "/assets/img/login-bg/login-bg.jpg",
    };
  },
  mounted() {
    if(this.currentUser){
      this.user = this.currentUser;
    }
    if(this.userImage){
      this.image = this.userImage;
    }
  },
  methods: {
    expand: function () {
      this.stat = this.stat == "expand" ? "collapse" : "expand";
    },
  },
  computed: {
    ...mapGetters("auth", ["currentUser", "userImage"]),
    ...mapGetters("devices", ["devices", "currentDevice"]),
  },
  watch: {
    currentUser: {
      handler: function (val) {
        this.user = val;
      },
      deep: true,
    },
    userImage: {
      handler: function (val) {
        this.image = val;
      },
      deep: true,
    },
  },
};
</script>
