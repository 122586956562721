<template>
  <div class="col border rounded" style="">
    <component
      v-for="(widget, index) in widgets"
      :key="index"
      v-bind:is="widget.type"
    ></component>
  </div>
</template>

<script>
export default {
  name: "Col",
  components: {
    ...(() => {
      const context = require.context("@/components/widgets", false, /.*.vue/);
      //console.log(context.keys().map((k) => context(k).default))
      return Object.fromEntries(
        context
          .keys()
          .map((k) => context(k).default)
          .filter((c) => c != undefined)
          .map((c) => [c.name, c])
      );
    })(),
  },
  props: {},
  data() {
    return {
      widgets: [
        {
          type: "Indicator",
          title: "Indicator 1",
          value: "10",
          unit: "",
          icon: "fa-tachometer",
          color: "primary",
          size: "lg",
        },
      ],
    };
  },
};
</script>

<style></style>
