<template>
  <b-modal id="editDevice" cancel-variant="default" class="modal-message">
    <template slot="modal-header">
      <h4 class="modal-title">Edición de instalación</h4>
      <a class="btn-close" @click="$bvModal.hide('editDevice')"></a>
    </template>
    <form novalidate="">
      <div class="form-group mb-2">
        <label class="form-label">Nombre</label>
        <input
          type="text"
          v-model="name"
          class="form-control"
          placeholder="Nombre de dispositivo"
          required="required"
        />
        <div class="help-block">Nombre del dispositivo</div>
      </div>
      <div class="form-group">
        <label class="form-label">Descripción</label>
        <textarea
          v-model="description"
          class="form-control"
          placeholder="Descripción"
        ></textarea>
        <div class="help-block">Descripción del dispositivo</div>
      </div>
      <div class="form-group" v-show="user.role">
        <label class="form-label">Posición</label>
        <input
          type="text"
          v-model="position"
          class="form-control"
          placeholder="lat,long,zoom,rotación,ángulo"
          required="required"
        />
        <div class="help-block">Coordenadas de la vista</div>
      </div>
      <div class="form-group" v-show="user.role">
        <label class="form-label">Peticiones</label>
        <input
          type="text"
          v-model="request"
          class="form-control"
          placeholder="r_0_50,c_0_160..."
          required="required"
        />
        <div class="help-block">Peticiones periodicas al PLC</div>
      </div>
    </form>
    <div class="help-block">
      <i class="fas fa-spinner fa-spin mx-auto" v-show="loading"></i
      ><span :class="msgClass">{{ msg }}</span>
    </div>
    <template #modal-footer="">
      <b-button @click="$bvModal.hide('editDevice')"> Cerrar </b-button>
      <b-button variant="danger" @click="actualizar"> Aplicar </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  components: {},
  props: { device: Object, user: Object},
  data() {
    return {
      usuario: null,
      loading: false,
      msg: "",
      msgClass: "",
      name: "",
      description: "",
      position: "",
      request: "",
    };
  },
  mounted() {
    this.loading = false;
    this.msg = "";
    this.msgClass = "";
  },
  methods: {
    ...mapActions("devices", ["updateDevice"]),
    async actualizar() {
      this.loading = true;
      this.msg = "Actualizando...";
      this.msgClass = "";
      const response = await this.updateDevice({
        id: this.device.id,
        device: {
          name: this.name,
          description: this.description,
          position: this.position,
          request: this.request,
        },
        user: this.currentUser,
      });
      if (response.msg) {
        this.msg = response.error;
        this.msgClass = "text-danger";
      } else {
        this.msg = "Dispositivo actualizado";
        this.msgClass = "text-success";
      }
      this.$bvModal.hide("editDevice");
      this.$emit("update");
      this.loading = false;
    },
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
  },
  watch: {
    device: function () {
      this.name = this.device.name;
      this.description = this.device.description;
      try {
        this.position = [
          this.device.position.lat,
          this.device.position.lng,
          this.device.position.zoom,
          this.device.position.rotation,
          this.device.position.tilt,
        ].join(", ");
      } catch (e) {
        console.log(e);
      }
      this.request = this.device.request;
      this.usuario = JSON.parse(localStorage.getItem("user"));
    },
  },
};
</script>
