var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu"},[_c('div',{staticClass:"menu-profile",style:({
      backgroundImage: 'url(' + _vm.activeImg + ')',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    })},[_c('a',{staticClass:"menu-profile-link",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.expand()}}},[_c('div',{staticClass:"with-shadow"}),_c('div',{staticClass:"\n          menu-profile-image menu-profile-image-icon\n          bg-gray-900\n          text-gray-600\n        "},[_c('img',{attrs:{"src":_vm.image,"alt":"user"}})]),_c('div',{staticClass:"menu-profile-info"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"flex-grow-1"},[_vm._v(" "+_vm._s(_vm.user.name)+" ")]),_c('div',{staticClass:"menu-caret ms-auto"})]),_c('small',[_vm._v(_vm._s(_vm.user.email))])])])]),_c('div',{staticClass:"collapse",class:{
      'd-block':
        this.stat == 'expand' && !this.appOptions.pageSidebarMinified,
      'd-none': this.stat == 'collapse',
    },attrs:{"id":"appSidebarProfileMenu"}},[_vm._m(0),_c('div',{staticClass:"menu-divider m-0"})])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu-item pt-5px"},[_c('a',{staticClass:"menu-link",attrs:{"href":"javascript:;"}},[_c('div',{staticClass:"menu-icon"},[_c('i',{staticClass:"fa fa-cog"})]),_c('div',{staticClass:"menu-text"},[_vm._v("Settings")])])])}]

export { render, staticRenderFns }