// export const myAction = async ({ commit }) => {

import deviceApi from "@/api/deviceApi";

export const disconnect = async ({ state }) => {
  state.mqtt.desconectar();
};

export const loadDevices = async ({ commit }, user) => {
  const { data } = await deviceApi.get("/", {
    headers: {
      "Content-Type": "application/json",
      "x-token": user.token,
    },
  });

  if (!data) {
    commit("setDevices", []);
    return;
  }
  data.user = user;
  commit("setDevices", data);
  return;
};

export const selectDevice = async ({ commit }, { id }) => {
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const { data } = await deviceApi.put(
    `/select/${id}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        "x-token": user.token,
      },
    }
  );

  if (!data.ok) {
    commit("setSelected", "");
    return;
  }

  commit("setSelected", id);
};

export const searchDevice = async ({ commit }, { serial }) => {
  const user = JSON.parse(localStorage.getItem("user")) || {};

  try {
    const { data } = await deviceApi.put(
      `/assign/${serial}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          "x-token": user.token,
        },
      }
    );

    if (data.ok) {
      commit("updateDevices", data);
      return true;
    }
  } catch (err) {
    console.error;
  }

  return false;
};

export const updateDevice = async ({ commit }, { device, user, id }) => {
  return new Promise((resolve, reject) => {
    try {
      fetch(`${process.env.VUE_APP_AUTHDOMAIN}/api/devices/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-token": user.token,
        },
        body: JSON.stringify({
          name: device.name,
          description: device.description,
          position: device.position,
          request: device.request,
        }),
      })
        .then((res) => res.json())
        .then((json) => {
          if (json.ok) {
            commit("updateDevice", json.device);
          }
          resolve(json);
        })
        .catch((error) => {
          reject(error);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const deleteDevice = async ({ commit }, { user, id }) => {
  console.log(user, id);
  return new Promise((resolve, reject) => {
    try {
      fetch(`${process.env.VUE_APP_AUTHDOMAIN}/api/devices/borrar/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "x-token": user.token,
        },
      })
        .then((resp) => resp.json())
        .then((json) => {
          if (json.ok) {
            commit("deleteDevice", id);
          }
          resolve(json);
        })
        .catch(console.error);
    } catch (error) {
      reject(error);
    }
  });
};

export const updateTemplate = async ({ commit }, template) => {
  commit("updateTemplate", { ...template });
};

export const getMarkers = async ({ commit }, { user, id }) => {
  return new Promise((resolve, reject) => {
    try {
      fetch(`${process.env.VUE_APP_AUTHDOMAIN}/api/markers/${id}`, {
        headers: {
          "Content-Type": "application/json",
          "x-token": user.token,
        },
      })
        .then((resp) => resp.json())
        .then((json) => {
          if (json.ok) {
            commit("setMarkers", json.markers);
          }
          resolve(json);
        })
        .catch((error) => {
          reject(error);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getVariables = async ({ commit }, { user, id }) => {
  return new Promise((resolve, reject) => {
    try {
      fetch(`${process.env.VUE_APP_AUTHDOMAIN}/api/variables/${id}`, {
        headers: {
          "Content-Type": "application/json",
          "x-token": user.token,
        },
      })
        .then((resp) => resp.json())
        .then((json) => {
          if (json.ok) {
            commit("setVariables", json.variables);
          }
          resolve(json);
        })
        .catch((error) => {
          reject(error);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const newVariable = async ({ commit }, { user, variable }) => {
  return new Promise((resolve, reject) => {
    try {
      fetch(`${process.env.VUE_APP_AUTHDOMAIN}/api/variables/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-token": user.token,
        },
        body: JSON.stringify(variable),
      })
        .then((resp) => resp.json())
        .then((json) => {
          if (json.ok) {
            commit("addVariable", json.variable);
          }
          resolve(json);
        })
        .catch((error) => {
          reject(error);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const updateVariable = async ({ commit }, { user, variable }) => {
  return new Promise((resolve, reject) => {
    try {
      fetch(`${process.env.VUE_APP_AUTHDOMAIN}/api/variables/${variable.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-token": user.token,
        },
        body: JSON.stringify(variable),
      })
        .then((resp) => resp.json())
        .then((json) => {
          if (json.ok) {
            commit("updateVariable", json.variable);
          }
          resolve(json);
        })
        .catch((error) => {
          reject(error);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getValue = ({ state }, reg) => {
  return getRequest(reg, state.buffer);
};

export const getRequest = (reg, buffer) => {
  let { dataType: type, address, offset, gain } = reg;
  if (!isNaN(parseFloat(address)) && isFinite(address)) return null;
  const area = address.split("_")[0];
  let index = address.split("_")[1];
  const bit = parseInt(address.split("_")[2]);
  offset = parseInt(offset);
  gain = parseFloat(gain);
  switch (area.toLowerCase()) {
    case "r":
      switch (type) {
        case "bool":
          return buffer.bit_test(buffer.getUint(index), bit) ? 1 : 0;
        case "int":
          return buffer.getInt(parseInt(index) + offset) * gain;
        case "uint":
          return buffer.getUint(parseInt(index) + offset) * gain;
        case "long":
          return buffer.getLong(parseInt(index) + offset) * gain;
        case "ulong":
          return buffer.getULong(parseInt(index) + offset) * gain;
        case "float":
          return buffer.getFloat(parseInt(index) + offset) * gain;
        default:
          return null;
      }
    case "c":
      return buffer.getBit(parseInt(index) + offset);
  }
  return null;
};

export const setValue = ({ state }, { register, values }) => {
  const reg = state.variables.find((v) => v.name == register);
  state.mqtt.publish(reg, values, state.buffer);
};

export const setWeatherData = ({ commit }, weatherData) => {
  commit("setWeatherData", weatherData);
};

export const setForecast = ({ commit }, forecast) => {
  commit("setForecast", forecast);
};

/* 
export const createEntry = async ({ commit }, entry) => {
  const { date, picture, text } = entry;
  const output = { date, picture, text };
  const { data } = await authApi.post(`/entries.json`, output);

  output.id = data.name;
  commit("addEntry", { ...output });
  return output.id
};

export const deleteEntry = async ({ commit }, id) => {
  await authApi.delete(`/entries/${id}.json`);
  commit("deleteEntry", id )
  
} */
