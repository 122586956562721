<template>
  <div>
    <!-- BEGIN #header -->
    <div
      id="header"
      class="app-header"
      v-bind:class="{ 'app-header-inverse': appOptions.appHeaderInverse }"
    >
      <!-- BEGIN navbar-header -->
      <div class="navbar-header">
        <button
          type="button"
          class="navbar-mobile-toggler"
          v-on:click="toggleSidebarEndMobile"
          v-if="appOptions.appSidebarTwo"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <router-link to="/" class="navbar-brand"
          ><img
            src="assets/img/logo-full.png"
            alt="logo"
            class="img-fluid"
            style="height: 42px"
        /></router-link>
        <button
          type="button"
          class="navbar-mobile-toggler"
          v-on:click="toggleTopMenuMobile"
          v-if="appOptions.appTopMenu && !appOptions.appSidebarNone"
        >
          <span class="fa-stack fa-lg text-inverse">
            <i class="far fa-square fa-stack-2x"></i>
            <i class="fa fa-cog fa-stack-1x"></i>
          </span>
        </button>
        <button
          type="button"
          class="navbar-mobile-toggler"
          v-on:click="toggleTopMenuMobile"
          v-if="appOptions.appTopMenu && appOptions.appSidebarNone"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <button
          type="button"
          class="navbar-mobile-toggler"
          v-on:click="toggleHeaderMegaMenuMobile"
          v-if="appOptions.appHeaderMegaMenu"
        >
          <span class="fa-stack fa-lg text-inverse m-t-2">
            <i class="far fa-square fa-stack-2x"></i>
            <i class="fa fa-cog fa-stack-1x"></i>
          </span>
        </button>
        <button
          type="button"
          class="navbar-mobile-toggler"
          v-on:click="toggleSidebarMobile"
          v-if="!appOptions.appSidebarNone"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
      </div>
      <!-- END navbar-header -->

      <!-- BEGIN header-nav -->
      <div class="navbar-nav">
        <header-mega-menu
          v-if="appOptions.appHeaderMegaMenu && currentDevice"
        ></header-mega-menu>

        <b-nav-item-dropdown
          left
          menu-class="dropdown-menu media-list"
          class="navbar-item navbar-user dropdown"
          toggle-class="navbar-link dropdown-toggle d-flex align-items-center"
          no-caret
          v-if="currentDevice"
        >
          <template slot="button-content">
            <span class="d-none d-md-inline"
              >{{ temp }}ºC ({{ weatherStation }})</span
            >
            <b class="ms-1 caret"></b>
            <div class="menu-profile-image menu-profile-image-icon">
              <img :src="weatherImg" alt="weather" />
            </div>
          </template>
          <div class="dropdown-header">
            El Tiempo
          </div>
          <div class="text-center w-300px py-2">Tiempo actual</div>
          <div class="text-center w-300px py-1">
            <img
              :src="weatherImg"
              alt="weather"
              style="width: 100px; height: 100px"
            />
            <h3>{{ temp }}ºC</h3>
            <h6>máx {{ max }}<small>ºC</small> min {{ min}}<small>ºC</small></h6>
            <h5>Humedad: {{ humidity }}%</h5>
            <h5>
              Viento: {{ winSpeed }} m/s ({{ winDeg }}º)
              <i
                class="fa fa-down-long"
                :style="'transform: rotate(' + winDeg + 'deg);'"
              ></i>
            </h5>
          </div>
          <div class="d-flex justify-content-center">
            <div v-for="(dia, index) in forecast" :key="index" class="p-2">
              <h6 class="text-center ps-12px">{{ dia.date }}</h6>
              <img
                class="mx-auto d-block"
                :src="
                  'https://openweathermap.org/img/wn/' + dia.icon + '@2x.png'
                "
                :alt="dia.iconAlt"
              />
              <h6 fa-border class="text-center">
                {{ dia.temp }}<small>ºC</small>
              </h6>
              <h6 class="text-center">
                {{ dia.humidity.toFixed(0) }}<small>%</small>
              </h6>
              <h6 class="text-center">
                {{ dia.wind.toFixed(1) }}<small>m/s</small>
              </h6>
            </div>
          </div>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown
          class="navbar-item"
          menu-class="dropdown-menu media-list"
          right
          toggle-class="navbar-link dropdown-toggle icon"
          no-caret
        >
          <template slot="button-content">
            <i class="fa fa-bell"></i>
            <span class="badge">0</span>
          </template>
          <div class="dropdown-header">NOTIFICATIONS (0)</div>
          <div class="text-center w-300px py-3">No notification found</div>
        </b-nav-item-dropdown>
        <!-- <b-nav-item-dropdown
          class="navbar-item"
          toggle-class="navbar-link dropdown-toggle"
          no-caret
          v-if="appOptions.appHeaderLanguageBar"
        >
          <template slot="button-content">
            <span class="flag-icon flag-icon-us me-1" title="us"></span>
            <span class="name d-none d-sm-inline me-1">EN</span>
            <b class="caret"></b>
          </template>
          <b-dropdown-item href="javascript:;"
            ><span class="flag-icon flag-icon-us" title="us"></span>
            English</b-dropdown-item
          >
          <b-dropdown-item href="javascript:;"
            ><span class="flag-icon flag-icon-cn" title="cn"></span>
            Chinese</b-dropdown-item
          >
          <b-dropdown-item href="javascript:;"
            ><span class="flag-icon flag-icon-jp" title="jp"></span>
            Japanese</b-dropdown-item
          >
          <b-dropdown-item href="javascript:;"
            ><span class="flag-icon flag-icon-be" title="be"></span>
            Belgium</b-dropdown-item
          >
          <b-dropdown-divider class="m-b-0"></b-dropdown-divider>
          <b-dropdown-item href="javascript:;" class="text-center"
            >more options</b-dropdown-item
          >
        </b-nav-item-dropdown> -->
        <b-nav-item-dropdown
          right
          menu-class="me-1"
          class="navbar-item navbar-user dropdown"
          toggle-class="navbar-link dropdown-toggle d-flex align-items-center"
          no-caret
        >
          <template slot="button-content">
            <div class="menu-profile-image menu-profile-image-icon">
              <img :src="image" alt="user" />
            </div>
            <span class="d-none d-md-inline">{{ user.name }}</span>
            <b class="caret"></b>
          </template>
          <a href="javascript:;" class="dropdown-item">Edit Profile</a>
          <a href="javascript:;" class="dropdown-item">Setting</a>
          <div class="dropdown-divider"></div>
          <a href="javascript:;" class="dropdown-item" @click="logOut"
            >Log Out</a
          >
        </b-nav-item-dropdown>
        <div
          class="navbar-divider d-none d-md-block"
          v-if="appOptions.appSidebarTwo"
        ></div>
        <div
          class="navbar-item d-none d-md-block"
          v-if="appOptions.appSidebarTwo"
        >
          <a
            href="javascript:;"
            v-on:click="toggleSidebarEnd"
            class="navbar-link icon"
          >
            <i class="fa fa-th"></i>
          </a>
        </div>
      </div>
      <!-- end header navigation right -->
    </div>
    <!-- end #header -->
  </div>
</template>

<script>
import AppOptions from "../../config/AppOptions.vue";
import HeaderMegaMenu from "./HeaderMegaMenu.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import {
  getCurrentWeather,
  getForecast,
  weatherIcon,
} from "@/helpers/openWeather";

export default {
  name: "Header",
  components: {
    HeaderMegaMenu,
  },
  data() {
    return {
      user: { name: "", email: "" },
      image: "/assets/img/user/avatar-m.png",
      appOptions: AppOptions,
      weatherStation: "No disponible",
      temp: "NA",
      max: "NA",
      min: "NA",
      humidity: "NA",
      winSpeed: "NA",
      winDeg: "NA",
      weatherImg: "https://openweathermap.org/img/wn/01d@2x.png",
      forecast: [],
    };
  },
  mounted() {
    if (this.currentUser) {
      this.user = this.currentUser;
      this.loadDevices(this.user);
    }
    if (this.userImage) {
      this.image = this.userImage;
    }
  },
  methods: {
    ...mapMutations("auth", ["setUser"]),
    ...mapActions("devices", ["loadDevices", "disconnect", "setWeatherData", "setForecast"]),
    toggleSidebarMobile() {
      this.appOptions.appSidebarMobileToggled =
        !this.appOptions.appSidebarMobileToggled;
    },
    toggleSidebarEnd() {
      this.appOptions.appSidebarEndToggled =
        !this.appOptions.appSidebarEndToggled;
    },
    toggleSidebarEndMobile() {
      this.appOptions.appSidebarEndMobileToggled =
        !this.appOptions.appSidebarEndMobileToggled;
    },
    toggleTopMenuMobile() {
      this.appOptions.appTopMenuMobileToggled =
        !this.appOptions.appTopMenuMobileToggled;
    },
    toggleHeaderMegaMenuMobile() {
      this.appOptions.appHeaderMegaMenuMobileToggled =
        !this.appOptions.appHeaderMegaMenuMobileToggled;
    },
    checkForm: function (e) {
      e.preventDefault();
      this.$router.push({ path: "/extra/search" });
    },
    logOut() {
      this.setUser(null);
      this.disconnect();
      localStorage.removeItem("user");
      this.$router.push("/");
    },
  },
  computed: {
    ...mapGetters("auth", ["currentUser", "userImage"]),
    ...mapGetters("devices", ["currentDevice"]),
  },
  watch: {
    currentUser: {
      handler: function (val) {
        this.user = val;
        this.loadDevices(val);
      },
      deep: true,
    },
    currentDevice: {
      handler: async function () {
        if (this.currentDevice) {
          this.weatherData = await getCurrentWeather(
            this.currentDevice.position
          );
          this.setWeatherData(this.weatherData);
          this.temp = this.weatherData.main.temp.toFixed(1);
          this.max = this.weatherData.main.temp_max.toFixed(1);
          this.min = this.weatherData.main.temp_min.toFixed(1);
          this.weatherStation = this.weatherData.name;
          this.humidity = this.weatherData.main.humidity;
          this.winSpeed = this.weatherData.wind.speed;
          this.winDeg = this.weatherData.wind.deg;
          this.weatherImg = weatherIcon(this.weatherData);
          this.forecast = await getForecast(this.currentDevice.position);
          this.setForecast(this.forecast);
        }
      },
      deep: true,
    },
    userImage: {
      handler: function (val) {
        this.image = val;
      },
      deep: true,
    },
  },
};
</script>
