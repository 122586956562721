
import MQTT from "@/helpers/mqtt";
import Memoria from "@/helpers/buffer";

export default () => ({
  mqtt: new MQTT(),
  credentials: null,
  buffer: new Memoria(),
  template: {rows:[[[]],[]]},
  markers: [],
  devices: [],
  variables: [],
  changed: 0,
  bufferChanged: 0,
  selected: "",
  weatherData: null,
  forecast: [],
});
