import Vue from 'vue'
import Vuex from 'vuex'

import auth from '../modules/iotIberica/store/auth'
import devices from '../modules/iotIberica/store/devices'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        auth,
        devices,
    },
})

export default store