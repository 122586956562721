<template>
  <b-dropdown :variant="className" offset="25" :text="selected" class="me-2">
    <b-dropdown-item @click="clicked(0)" href="javascript:;">{{
      texts[0]
    }}</b-dropdown-item>
    <b-dropdown-item @click="clicked(1)" href="javascript:;">{{
      texts[1]
    }}</b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "SelectButton",
  props: {
    texts: {
      type: Object,
      default: () => ({
        0: "Off",
        1: "On",
      }),
      required: true,
    },
    classNames: {
      type: Object,
      default: () => ({
        0: "default",
        1: "primary",
      }),
      required: true,
    },
    tags: {
      type: Object,
      default: () => ({
        0: null,
        1: null,
      }),
      required: true,
    },
    actions: {
      type: Object,
      default: function () {
        return {
          0: "reset",
          1: "set",
        };
      },
    },
  },
  data() {
    return {
      transactions: 0,
      state: 0,
    };
  },
  computed: {
    ...mapGetters("devices", ["currentDevice", "getValue", "bufferChanged"]),
    selected() {
      return this.state
        ? this.texts[1]
        : this.texts[0];
    },
    className() {
      return this.state
        ? this.classNames[1]
        : this.classNames[0];
    },
  },
  methods: {
    ...mapActions("devices", ["setValue"]),
    clicked(index) {
      const action = this.actions[index] || this.actions[0];
      const register = this.tags[1] || this.tags[0];
      console.log(action, register);
      switch (action) {
        case "toggle":
          this.setValue({ register, values: this.state ? 0 : 1 });
          break;
        case "set":
          this.setValue({ register, values: 1 });
          break;
        case "reset":
          this.setValue({ register, values: 0 });
          break;
      }
      /* this.$store.dispatch(this.action, {
        variable: this.variable[state],
        value: this.getValue(this.variable[state]),
      }); */
    },
  },
  watch: {
    bufferChanged(nv) {
      this.state = this.getValue(this.tags[0]);
      this.transactions = nv;
    },
  },
};
</script>
