<template>
  <!-- BEGIN register -->
  <div class="register register-with-news-feed">
    <!-- BEGIN news-feed -->
    <div class="news-feed">
      <div
        class="news-image"
        style="background-image: url(/assets/img/login-bg/login-bg-15.jpg)"
      ></div>
      <div class="news-caption">
        <img
          src="assets/img/logo-full.png"
          alt="logo"
          class="img-fluid"
          style="height: 100px"
        />
        <p>
          Regístrese y acceda a su panel de control para comenzar a controlar
          sus instalaciones utilizando la tecnología IOT (Internet of Things).
        </p>
      </div>
    </div>
    <!-- END news-feed -->

    <!-- BEGIN register-container -->
    <div class="register-container">
      <!-- BEGIN register-header -->
      <div class="register-header mb-25px h1">
        <div class="mb-1">Registro</div>

        <small class="h3 fw-300 mt-3 mb-5 hidden-sm-down">
          Disfruta de la aplicación IoT Ibérica en tu móvil, ordenador o tablet.
          <br />¡Vamos!
        </small>
      </div>
      <!-- END register-header -->

      <!-- BEGIN register-content -->
      <div class="register-content">
        <form v-on:submit="checkForm" method="POST" class="fs-13px">
          <div class="mb-3">
            <label class="mb-2"
              >Nombre <span class="text-danger">*</span></label
            >
            <div class="row gx-3">
              <div class="col-md-6 mb-2 mb-md-0">
                <b-form-input
                  v-model="user.name"
                  type="text"
                  class="form-control fs-13px"
                  placeholder="Nombre"
                  :state="nameState"
                ></b-form-input>
              </div>
              <div class="col-md-6">
                <b-form-input
                  v-model="user.lastName"
                  type="text"
                  class="form-control fs-13px"
                  placeholder="Apellido"
                  :state="lastNameState"
                ></b-form-input>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <label class="mb-2">Email <span class="text-danger">*</span></label>
            <b-form-input
              v-model="user.email"
              type="text"
              class="form-control fs-13px"
              placeholder="Email"
              :state="validate_email"
            ></b-form-input>
          </div>
          <div class="mb-4">
            <label class="mb-2"
              >Password <span class="text-danger">*</span></label
            >
            <b-form-input
              v-model="user.password"
              type="password"
              class="form-control fs-13px"
              placeholder="Password"
              :state="good_password"
            ></b-form-input>
          </div>
          <div class="form-check mb-4">
            <input
              v-model="user.terms"
              class="form-check-input"
              type="checkbox"
              value=""
              id="agreementCheckbox"
            />
            <label class="form-check-label" for="agreementCheckbox">
              Haciendo click en registrar, estás de acuerdo con
              <a href="javascript:;">Terminos de uso</a> y que te has leido
              nuestra <a href="javascript:;">política de privacidad</a>,
              incluida nuestro <a href="javascript:;">uso de cookies</a>.
            </label>
          </div>
          <div class="form-check mb-4">
            <input
              v-model="user.newsletter"
              class="form-check-input"
              type="checkbox"
              value=""
              id="newsCheckbox"
            />
            <label class="form-check-label" for="newsCheckbox">
              Subscribirme al boletín de noticias.
            </label>
          </div>
          <div class="mb-4">
            <button
              type="submit"
              class="btn btn-primary d-block w-100 btn-lg h-45px fs-13px"
              :disabled="!validar"
            >
              Registarse
            </button>
          </div>
          <div class="mb-4 pb-5">
            ¿Ya eres miembro? Haz
            <router-link to="/login"> aquí</router-link> para acceder.
          </div>
          <hr class="bg-gray-600 opacity-2" />
          <p class="text-center text-gray-600">
            2021 © IoT Ibérica S.L. by&nbsp;<a
              href="https://iotiberica.com"
              class="text-dark fw-500"
              title="iotiberica.com"
              target="_blank"
              >iotiberica.com</a
            >
          </p>
        </form>
      </div>
      <!-- END register-content -->
    </div>
    <!-- END register-container -->
  </div>
  <!-- END register -->
</template>

<script>
import AppOptions from "../config/AppOptions.vue";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      user: {
        name: "",
        lastName: "",
        email: "",
        password: "",
        terms: false,
        newsletter: false,
      },
    };
  },
  created() {
    AppOptions.appEmpty = true;
  },
  beforeRouteLeave(to, from, next) {
    AppOptions.appEmpty = false;
    next();
  },
  methods: {
    ...mapActions("auth", ["register"]),
    validation() {
      if (
        !this.user.terms ||
        this.user.name == "" ||
        this.user.lastName == "" ||
        !this.validateEmail(this.user.email) ||
        !this.goodPassword(this.user.password)
      ) {
        return false;
      }
      return true;
    },
    validateEmail(email) {
      if (email.length == 0) return false;
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
        ? true
        : false;
    },
    goodPassword: (password) => {
      if (password.length == 0) return false;
      //a custom validator!
      return (
        password.length >= 6 &&
        /[a-z]/.test(password) &&
        /[A-Z]/.test(password) &&
        /[0-9]/.test(password)
      );
    },

    async checkForm(e) {
      e.preventDefault();
      this.register(this.user).then((data) => {
        if (data.ok) {
          this.$bvModal
            .msgBoxOk([data.msg], {
              title: ["Registro completado"],
              buttonSize: "sm",
              centered: true,
              okVariant: "primary",
              okTitle: "OK",
              footerClass: "p-2",
              noCloseOnBackdrop: true,
              noCloseOnEsc: true,
              hideHeaderClose: true,
              size: "sm",
            })
            .then((resp) => {
              if (!resp) return;
              this.$router.push("/login");
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.$bvModal
            .msgBoxOk([data.msg], {
              title: ["Error"],
              buttonSize: "sm",
              centered: true,
              okVariant: "primary",
              okTitle: "OK",
              footerClass: "p-2",
              noCloseOnBackdrop: true,
              noCloseOnEsc: true,
              hideHeaderClose: true,
              size: "sm",
            })
            .then((resp) => {
              if (!resp) return;
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
  },
  computed: {
    nameState() {
      if (this.user.name.length == 0) return false;
      return this.user.name.length > 2 ? true : false;
    },
    lastNameState() {
      if (this.user.lastName.length == 0) return false;
      return this.user.lastName.length > 2 ? true : false;
    },
    validate_email() {
      return this.validateEmail(this.user.email) ? true : false;
    },
    good_password() {
      return this.goodPassword(this.user.password) ? true : false;
    },
    validar() {
      if (
        !this.user.terms ||
        this.user.name == "" ||
        this.user.lastName == "" ||
        !this.validateEmail(this.user.email) ||
        !this.goodPassword(this.user.password)
      ) {
        return false;
      }
      return true;
    },
  },
};
</script>
