// export const myGetter = ( state ) => {
//  return state
// }

export const changed = (state) => {
  return state.changed;
};

export const buffer = (state) => {
  return state.buffer;
};

export const getValue = (state) => (variable) => {
  const reg = state.variables.find((v) => v.name == variable);
  if (!reg) return null;
  let { dataType: type, address, offset, gain } = reg;
  if (!isNaN(parseFloat(address)) && isFinite(address)) return null;
  const area = address.split("_")[0];
  let index = address.split("_")[1];
  const bit = parseInt(address.split("_")[2]);
  offset = parseInt(offset);
  gain = parseFloat(gain);
  switch (area.toLowerCase()) {
    case "r":
      switch (type) {
        case "bool":
          return state.buffer.bit_test(state.buffer.getUint(index), bit) ? 1 : 0;
        case "int":
          return state.buffer.getInt(parseInt(index) + offset) * gain;
        case "uint":
          return state.buffer.getUint(parseInt(index) + offset) * gain;
        case "long":
          return state.buffer.getLong(parseInt(index) + offset) * gain;
        case "ulong":
          return state.buffer.getULong(parseInt(index) + offset) * gain;
        case "float":
          return state.buffer.getFloat(parseInt(index) + offset) * gain;
        default:
          return null;
      }
    case "c":
      return state.buffer.getBit(parseInt(index) + offset);
  }
  return null;
};

export const bufferChanged = (state) => {
  return state.bufferChanged;
};

export const currentDevice = (state) => {
  const idx = state.devices.map((e) => e.id).indexOf(state.selected);
  return state.devices[idx];
};

export const devices = (state) => {
  return state.devices;
};

export const selected = (state) => {
  return state.selected;
};

export const variables = (state) => {
  return state.variables;
};

export const template = (state) => {
  return state.template;
};

export const currentDeviceImage = (state) => {
  const idx = state.devices.map((e) => e.id).indexOf(state.selected);
  const device = state.devices[idx];
  const w = 300;
  const h = 188;
  if (!device) return "/assets/img/gallery/device-thumb.jpg";
  if (!device.img.includes("device-thumb")) {
    let arrImg = device.img.split("/");
    if (arrImg.length < 4) return device.img;
    let deviceType = `w_${w},h_${h},c_thumb,g_faces`;
    arrImg.splice(arrImg.length - 2, 0, deviceType);
    return arrImg.join("/");
  } else {
    return device.img;
  }
};

export const getEntriesByTerm =
  (state) =>
  (term = "") => {
    if (term.length === 0) return state.entries;

    return state.entries.filter((entry) =>
      entry.text.toLowerCase().includes(term.toLocaleLowerCase())
    );
  };

// id
export const getEntryById =
  (state) =>
  (id = "") => {
    const entry = state.entries.find((entry) => entry.id === id);

    if (!entry) return;

    return { ...entry }; // TODO: prueben
  };

  export const getWeatherData = (state) => {
    return state.weatherData;
  };

  export const getForecast = (state) => {
    return state.forecast;
  };
