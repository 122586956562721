import { Options } from '../../../dist/assets/plugins/perfect-scrollbar/types/perfect-scrollbar';
<template>
    <div class="row" style="">
      <componet
        v-for="(child, index) in children"
        :is="child.type"
        :data="child"
        :key="index"
        :options="child.options"
      ></componet>
    </div>
</template>

<script>

export default {
  name: "Row",
  components: {
    ...(() => {
      const context = require.context("@/components/widgets", false, /.*.vue/);
      //console.log(context);
      return Object.fromEntries(
        context
          .keys()
          .map((k) => context(k).default)
          .filter((c) => c != undefined)
          .map((c) => [c.name, c])
      );
    })(),
  },
  props: {
    children: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  mounted() {
    
  },
  methods: {
    
  },
  
};
</script>

<style scoped>

</style>
