<template>
  <div>
    <!-- BEGIN login -->
    <div class="login login-v2 fw-bold">
      <!-- BEGIN login-cover -->
      <div class="login-cover">
        <div
          class="login-cover-img"
          v-bind:style="{ backgroundImage: 'url(' + bg.activeImg + ')' }"
        ></div>
        <div class="login-cover-bg"></div>
      </div>
      <!-- END login-cover -->

      <!-- BEGIN login-container -->
      <div class="login-container">
        <!-- BEGIN login-header -->
        <div class="login-header">
          <div class="brand">
            <div class="d-flex align-items-center">
              <img
                src="assets/img/logo-full.png"
                alt="logo"
                class="img-fluid mx-auto"
                style="height: 64px"
              />
            </div>
            <small class="text-center">Verificación de email</small>
          </div>
        </div>
        <!-- END login-header -->
        <!-- end row -->
        <div class="row justify-content-center">
          <div class="">
            <div v-if="success" class="mb-4">
              <div class="card-body">
                <div class="p-2">
                  <div class="text-center">
                    <div class="p-2 mt-4">
                      <h4 class="fw-semibold">Éxito !</h4>
                      <p class="text-muted">
                        <strong>¡Todo está bien!</strong> Ahora puede empezar a
                        usar la plataforma.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="error" class="card">
              <div class="card-body">
                <div class="p-2">
                  <div class="text-center">
                    <div class="p-2 mt-2">
                      <h4 class="text-muted">Algo fué mal !</h4>
                      <p class="text-muted">
                        Compruebe que se registró correctamente o intente
                        recuperar su contraseña
                        <router-link to="/forgot-password">aquí</router-link>.
                      </p>
                      <div class="mt-4">
                        <router-link to="/">Inicio</router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="checking" class="mb-4">
              <div class="card-body">
                <div class="login-header">
                  <div class="brand">
                    <small>Comprobando...</small>
                  </div>
                  <div class="icon">
                    <i class="fa fa-envelope"></i>
                  </div>
                </div>
              </div>
            </div>
            <!-- end card -->
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
        <!-- BEGIN login-content -->
        <div v-if="success" class="login-content">
          <div class="mb-20px">
            <button
              type="submit"
              class="btn btn-success d-block w-100 h-45px btn-lg"
              @click="goTo"
            >
              Acceder
            </button>
          </div>
        </div>
        <!-- END login-content -->
      </div>
      <!-- END login-container -->
    </div>
    <!-- END login -->
  </div>
</template>

<script>
import AppOptions from "../config/AppOptions.vue";
import { mapActions } from "vuex";

export default {
  name: "confirm_mail",
  created() {
    AppOptions.appEmpty = true;
  },
  beforeRouteLeave(to, from, next) {
    AppOptions.appEmpty = false;
    next();
  },
  data() {
    return {
      checking: true,
      success: false,
      error: false,
      bg: {
        activeImg: "/assets/img/login-bg/login-bg.jpg",
      },
    };
  },
  mounted() {
    this.check();
  },
  methods: {
    ...mapActions("auth", ["verifyAcount"]),
    goTo(){
      this.$router.push("/");
    },
    async check() {
      const { token } = this.$router.currentRoute.query;
      const resp = await this.verifyAcount({ token });
      this.checking = false;
      if (resp.ok) {
        if (resp.ok) this.success = true;
        else this.error = true;
      } else {
        this.error = true;
      }
    },
  },
};
</script>
