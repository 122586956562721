<template>
  <div>
    <div
      class="position-absolute w-100 h-100 top-0 start-0 bottom-0 end-0"
      id="map"
    ></div>

    <div
      class="p-1 position-relative"
      style="background: rgba(80, 80, 80, 0.5)"
    >
      <!-- BEGIN page-header -->
      <h1 class="page-header text-white">
        Mapa
        <small class="text-white">{{ finca }}</small>
      </h1>
      <!-- END page-header -->
    </div>
    <div class="pt-1 position-relative">
      <div class="d-inline-flex">
        <div class="mb-3 d-flex flex-column">
          <label
            for="manual-1"
            class="form-label text-white"
            style="z-index: 9999 !important"
            >El Romeral</label
          >
          <select-button
            id="manual-1"
            :texts="{ 1: 'Manual', 0: 'Automático' }"
            :classNames="{ 1: 'warning', 0: 'primary' }"
            :tags="{ 1: 'manual', 0: 'manual' }"
            :actions="{ 1: 'set', 0: 'reset' }"
          ></select-button>
        </div>
        <div class="mb-3 d-flex flex-column">
          <label
            for="manual-2"
            class="form-label text-white"
            style="z-index: 9999 !important"
            >El Guarda</label
          >
          <select-button
            id="manual-2"
            :texts="{ 1: 'Manual', 0: 'Automático' }"
            :classNames="{ 1: 'warning', 0: 'primary' }"
            :tags="{ 1: 'manual_2', 0: 'manual_2' }"
            :actions="{ 1: 'set', 0: 'reset' }"
          ></select-button>
        </div>
        <div class="mb-3 d-flex flex-column">
          <label for="modo" class="form-label text-white">Modo</label>
          <select-button
            id="modo"
            :texts="{ 1: 'Simulación', 0: 'Real' }"
            :classNames="{ 1: 'danger', 0: 'success' }"
            :tags="{ 1: 'simulacion', 0: 'simulacion' }"
            :actions="{ 1: 'set', 0: 'reset' }"
          ></select-button>
        </div>
      </div>
    </div>
    <div
      class="app-content-padding position-absolute fixed-bottom"
      style="background: rgba(80, 80, 80, 0.8)"
    >
      <b-button variant="primary" @click="loadGLTF">Load Pivot</b-button>
    </div>
  </div>
</template>
<script
  type="text/javascript"
  src="https://cdnjs.cloudflare.com/ajax/libs/suncalc/1.8.0/suncalc.min.js"
></script>
<script>
import AppOptions from "../config/AppOptions.vue";
import "mapbox-gl/dist/mapbox-gl.css";

import { mapGetters, mapActions } from "vuex";

const mapboxgl = require("mapbox-gl");

export default {
  name: "Mapbox",
  components: {},
  created() {
    AppOptions.appContentFullHeight = true;
    AppOptions.appContentClass = "p-0 position-relative";
  },
  data() {
    return {
      map: null,
      centro: null,
      collection: null,
      accessToken:
        "pk.eyJ1Ijoiam9zZWx1aXNtYiIsImEiOiJja3hhM251OG0wa25rMzJrdDdvYXQ5Y2FrIn0.2Se7Mg0MDLT7ahjg5K43MA", // your access token. Needed if you using Mapbox maps
      mapStyle: "mapbox://styles/mapbox/satellite-v9", // your map style
      transactions: 0,
    };
  },
  mounted() {
    if (this.currentDevice) {
      //this.setMapa();
      // this.getItems();
      mapboxgl.accessToken = this.accessToken;
      this.map = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/joseluismb/ckxelqjyt6f5c14ob2rxa7h2y",
        // camera options properties - https://docs.mapbox.com/help/glossary/camera/
        center: [
          this.currentDevice.position.lng,
          this.currentDevice.position.lat,
        ],
        pitch: 65, // pitch in degrees
        bearing: 90, // bearing in degrees
        zoom: 15,
      });
      this.map.on("load", () => {
        this.map.addLayer({
          id: "sky",
          type: "sky",
          paint: {
            "sky-opacity": [
              "interpolate",
              ["linear"],
              ["zoom"],
              0,
              0,
              5,
              0.3,
              8,
              1,
            ],
            "sky-type": "atmosphere",
            "sky-atmosphere-sun": this.getSunPosition(),
            "sky-atmosphere-sun-intensity": 10,
          },
        });
        this.map.setFog({
          range: [-1, 20],
          color: "white",
          "horizon-blend": 0.1,
        });
        /* this.map.addSource("places", this.collection);
        this.map.addLayer({
          id: "maine",
          type: "fill",
          source: "places", // reference the data source
          layout: {},
          paint: {
            "fill-color": "#0080ff", // blue color fill
            "fill-opacity": 0.5,
          },
          filter: ["==", "$type", "Polygon"],
        });
        // Add a black outline around the polygon.
        this.map.addLayer({
          id: "pivot",
          type: "circle",
          source: "places",
          layout: {},
          paint: {
            "circle-radius": ['get', 'radius'],
            "circle-color": "#0080ff",
          },
          filter: ["==", "$type", "Point"],
        }); */
        this.getItems();
      });
    }
    localStorage.setItem("screen", "mapbox");
  },
  beforeDestroy() {},
  computed: {
    ...mapGetters("devices", ["currentDevice", "getValue", "bufferChanged"]),
    ...mapGetters("auth", ["currentUser"]),
    finca: function () {
      if (!this.currentDevice) return "";
      return this.currentDevice.name
        ? this.currentDevice.name
        : '<i class="fas fa-spinner fa-spin"></i>';
    },
  },
  beforeRouteLeave(to, from, next) {
    AppOptions.appContentFullHeight = false;
    AppOptions.appContentClass = "";
    next();
  },
  methods: {
    ...mapActions("devices", ["getMarkers", "setValue"]),
    getSunPosition(date) {
      const center = this.map.getCenter();
      const sunPos = SunCalc.getPosition(
        date || Date.now(),
        center.lat,
        center.lng
      );
      const sunAzimuth = 180 + (sunPos.azimuth * 180) / Math.PI;
      const sunAltitude = 90 - (sunPos.altitude * 180) / Math.PI;
      return [sunAzimuth, sunAltitude];
    },
    /* async onMapLoad(event) {
      // Here we cathing 'load' map event
      const asyncActions = event.component.actions;

      const newParams = await asyncActions.flyTo({
        center: [
          this.currentDevice.position.lng,
          this.currentDevice.position.lat,
        ],
        zoom: 17,
        pitch: 75,
        speed: 1,
      });
      console.log(newParams);
      
    }, */
    loadGLTF() {
      this.items.forEach((item) => {
        if (!["pivot"].includes(item.type)) return;
        if (item.gltfOn) this.unloadGLTF(item.gltfOn);

        let altura = this.google.maps.geometry.spherical.computeDistanceBetween(
          new this.google.maps.LatLng(
            this.currentDevice.position.lat,
            item.center.lng()
          ),
          { lat: item.center.lat(), lng: item.center.lng() }
        );
        if (item.center.lat() < this.currentDevice.position.lat) {
          altura *= -1;
        }

        let anchura =
          this.google.maps.geometry.spherical.computeDistanceBetween(
            new this.google.maps.LatLng(
              item.center.lat(),
              this.currentDevice.position.lng
            ),
            { lat: item.center.lat(), lng: item.center.lng() }
          );
        if (item.center.lng() < this.currentDevice.position.lng) {
          anchura *= -1;
        }
        console.log(altura);
        console.log(anchura);
        this.loader.load("assets/3d/pivot-v.glb", (gltf) => {
          gltf.scene.scale.set(item.radius / 200, 12, 12);
          gltf.scene.rotation.x = (90 * Math.PI) / 180; // rotations are in radians
          gltf.scene.rotation.y = (90 * Math.PI) / 180;
          gltf.scene.position.y = altura;
          gltf.scene.position.x = anchura;
          gltf.scene.visible = false;
          this.scene.add(gltf.scene);
          item.gltfOn = gltf;
        });
        if (item.gltfOff) this.unloadGLTF(item.gltfOff);
        this.loader.load("assets/3d/pivot-r.glb", (gltf) => {
          gltf.scene.scale.set(item.radius / 200, 12, 12);
          gltf.scene.rotation.x = (90 * Math.PI) / 180; // rotations are in radians
          gltf.scene.rotation.y = (90 * Math.PI) / 180;
          gltf.scene.position.y = altura;
          gltf.scene.position.x = anchura;
          this.scene.add(gltf.scene);
          item.gltfOff = gltf;
        });
      });
    },
    unloadGLTF(gltf) {
      //var selectedObject = this.scene.getObjectByName('pivot');
      this.scene.remove(gltf.scene);
    },
    setMapa() {},
    getItems() {
      this.getMarkers({
        user: this.currentUser,
        id: this.currentDevice.id,
      }).then((data) => {
        if (!data.ok) return;
        console.log(data.markers);
        /* data.markers.forEach((marker) => {
          try {
            new mapboxgl.Marker()
              .setLngLat([
                marker.marker.position.lng,
                marker.marker.position.lat,
              ])
              .addTo(this.map);
          } catch (e) {
            console.error(e);
          }
        }); */
      });
    },
    clickMarker(marker) {
      const action = marker.action;
      const register = marker.writeReg;
      const value = this.getValue(marker.reg);
      let question = "";
      switch (action) {
        case "toggle":
          question = value
            ? `¿Desactivar ${marker.name}?`
            : `¿Activar  ${marker.name}?`;
          break;
        case "set":
          question = `¿Activar ${marker.name}?`;
          break;
        case "reset":
          question = `¿Desactivar ${marker.name}?`;
          break;
        default:
          return;
      }
      this.$bvModal
        .msgBoxConfirm(question, {
          title: "¡Atención!",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Confirmar",
          cancelTitle: "Cancelar",
          footerClass: "p-2",
          noCloseOnBackdrop: true,
          noCloseOnEsc: true,
          hideHeaderClose: true,
          centered: true,
        })
        .then((resp) => {
          if (!resp) return;
          switch (action) {
            case "toggle":
              this.setValue({ register, values: value ? 0 : 1 });
              break;
            case "set":
              this.setValue({ register, values: 1 });
              break;
            case "reset":
              this.setValue({ register, values: 0 });
              break;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateItems() {
      return;
      /* for (let i = 0; i < this.items.length; i++) {
        if (!this.items[i]) continue;
        if (!this.items[i].reg) continue;
        const value = this.getValue(this.items[i].reg);
        //const auxValue = this.getValue(this.items[i].auxRegs);
        const {
          name,
          classes,
          onColor,
          offColor,
          onClass,
          offClass,
          onText,
          offText,
          preText,
          postText,
        } = this.items[i];
        if (value) {
          if (this.items[i].type == "marker") {
            let label = this.items[i].getLabel();
            label.text = `${preText}${onText}${postText}` || name || label.text;
            const className = onClass || classes;
            this.items[i].setLabel({ text: label.text, className });
          }
          if (this.items[i].type == "pivot") {
            try {
              this.items[i].gltfOn.scene.visible = true;
              this.items[i].gltfOff.scene.visible = false;
              this.items[i].gltfOff.scene.rotation.y += -0.01;
              this.items[i].gltfOn.scene.rotation.y += -0.01;
            } catch (error) {
              console.log(error);
            }
          }
          this.items[i].setOptions({
            fillColor: onColor,
            strokeColor: onColor,
          });
        } else {
          if (this.items[i].type == "marker") {
            let label = this.items[i].getLabel();
            label.text =
              `${preText}${offText}${postText}` || name || label.text;
            label.className = offClass || label.className;
            this.items[i].setLabel(label);
          }
          if (this.items[i].type == "pivot") {
            try {
              this.items[i].gltfOn.scene.visible = false;
              this.items[i].gltfOff.scene.visible = true;
            } catch (error) {
              console.log(error);
            }
          }
          this.items[i].setOptions({
            fillColor: offColor,
            strokeColor: offColor,
          });
        }
      } */
    },
    markerClicked(marker) {
      console.log(marker);
    },
  },
  watch: {
    currentDevice: function (n, o) {
      if (!o && n) {
        this.getItems();
        this.setMapa();
        return;
      }
      if (n.name != o.name) {
        /* this.setMapa();
        this.getItems(); */
      }
    },
    bufferChanged(nv) {
      /* this.items.forEach((item) => {
        if (this.getValue(item.reg)) {
          item.setOptions({
            fillColor: item.onColor,
            strokeColor: item.onColor,
          });
        } else {
          item.setOptions({
            fillColor: item.offColor,
            strokeColor: item.offColor,
          });
        }
      }); */
      this.updateItems();
      this.transactions = nv;
    },
  },
};
</script>
