const getItems = (device) => {
  return new Promise((resolve, reject) => {
    try {
      const user = JSON.parse(localStorage.getItem("user")) || {};
      fetch(`${process.env.VUE_APP_AUTHDOMAIN}/api/widgets/${device.id}`, {
        headers: {
          "Content-Type": "application/json",
          "x-token": user.token,
        },
      })
        .then((resp) => resp.json())
        .then((json) => {
          if (json.ok) {
            resolve(json);
          } else {
            reject(json);
          }
        })
        .catch((e) => {
          reject({ ok: false, e });
        });
    } catch (e) {
      reject({ ok: false, e });
    }
  });
};

const newItem = (item) => {
  return new Promise((resolve, reject) => {
    try {
      const user = JSON.parse(localStorage.getItem("user")) || {};
      fetch(`${process.env.VUE_APP_AUTHDOMAIN}/api/widgets`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-token": user.token,
        },
        body: JSON.stringify(item),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.ok) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((e) => {
          reject({ ok: false, e });
        });
    } catch (e) {
      reject({ ok: false, e });
    }
  });
};

const updateItem = (item) => {
  return new Promise((resolve, reject) => {
    try {
      const user = JSON.parse(localStorage.getItem("user")) || {};
      fetch(`${process.env.VUE_APP_AUTHDOMAIN}/api/widgets/${item.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-token": user.token,
        },
        body: JSON.stringify(item),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.ok) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((e) => {
          reject({ ok: false, e });
        });
    } catch (e) {
      reject({ ok: false, e });
    }
  });
};

const deleteItem = (item) => {
  return new Promise((resolve, reject) => {
    try {
      const user = JSON.parse(localStorage.getItem("user")) || {};
      fetch(`${process.env.VUE_APP_AUTHDOMAIN}/api/widgets/${item.id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "x-token": user.token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.ok) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((e) => {
          reject({ ok: false, e });
        });
    } catch (e) {
      reject({ ok: false, e });
    }
  });
};

module.exports = {
  getItems,
  newItem,
  updateItem,
  deleteItem,
};
