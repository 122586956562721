<template>
  <div>
    <!-- BEGIN page-header -->
    <h1 class="page-header">Controles</h1>
    <!-- END page-header -->
    <!-- BEGIN panel -->
    <b-tabs
      nav-class="nav-tabs-inverse"
      nav-wrapper-class="nav nav-tabs nav-tabs-inverse"
    >
      <div class="tab-content rounded-bottom p-3 panel rounded-0">
        <!-- BEGIN tab-pane -->
        <b-tab active>
          <template slot="title">
            <i class="fa fa-fw fa-lg fa-location-dot"></i>
            <span class="d-none d-xl-inline"> Marcadores</span>
          </template>
          <span class="badge bg-success fs-10px nuevo float-end p-2" @click="openModalNew('marker')"
              >Nuevo</span
            >
          <h4 class="mb-1"><b>Marcadores</b></h4>
          <p class="mb-3">
            Marcadores con icono o sin icono para mostrar en el mapa
          </p>
          <!-- BEGIN table-responsive -->
          <div class="table-responsive">
            <table
              class="table table-striped mb-0 align-middle table-condensed"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nombre</th>
                  <th>Tipo</th>
                  <th>Subtipo</th>
                  <th width="1%"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in itemsMarkers"
                  :key="'itemMarker-' + index"
                >
                  <td>
                    <img
                      :src="
                        item.marker.icon
                          ? item.marker.icon.url
                          : '/img/google-map-marker.png'
                      "
                      class="rounded h-30px"
                    />
                  </td>
                  <td>{{ item.marker.name }}</td>
                  <td>{{ item.marker.type }}</td>
                  <td>{{ item.marker.subType }}</td>
                  <td>
                    <div class="d-flex d-inline-flex">
                      <div
                        class="
                          widget-icon
                          rounded
                          bg-success
                          me-5px
                          mb-5px
                          text-white
                        "
                        @click="saveItem(index, itemsMarkers)"
                      >
                        <i class="fa fa-save"></i>
                      </div>
                      <div
                        class="
                          widget-icon
                          rounded
                          bg-gray
                          me-5px
                          mb-5px
                          text-white
                        "
                        @click="openModalEditMap(index, itemsMarkers)"
                      >
                        <i class="fa fa-map-marked-alt"></i>
                      </div>
                      <div
                        class="
                          widget-icon
                          rounded
                          bg-primary
                          me-5px
                          mb-5px
                          text-white
                        "
                        @click="showModal(index, itemsMarkers, 'marker' )"
                      >
                        <i class="fa fa-edit"></i>
                      </div>
                      <b-modal
                        :id="`modal-edit-marker-${index}`"
                        :title="item.marker.name"
                        @ok="editar(index, itemsMarkers)"
                      >
                        <textarea
                          cols="50"
                          rows="10"
                          v-model="itemsMarkers[index].prettyJSON"
                        ></textarea>
                      </b-modal>
                      <div
                        class="
                          widget-icon
                          rounded
                          bg-danger
                          me-5px
                          mb-5px
                          text-white
                        "
                        @click="borrar(index, itemsMarkers)"
                      >
                        <i class="fa fa-trash"></i>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
        <!-- END tab-pane -->
        <!-- BEGIN tab-pane -->
        <b-tab>
          <template slot="title">
            <i class="fa fa-fw fa-lg fa-draw-polygon"></i>
            <span class="d-none d-xl-inline"> Polígonos</span>
          </template>
          <span class="badge bg-success fs-10px nuevo float-end p-2" @click="openModalNew('polygon')"
              >Nuevo</span
            >
          <h4 class="mb-1"><b>Polígonos</b></h4>
          <p class="mb-3">Polígonos dibujados en el mapa</p>
          <!-- BEGIN table-responsive -->
          <div class="table-responsive">
            <table
              class="table table-striped mb-0 align-middle table-condensed"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nombre</th>
                  <th>Tipo</th>
                  <th>Subtipo</th>
                  <th width="1%"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in itemsPolygons"
                  :key="'itemPoligon-' + index"
                >
                  <td>
                    <img
                      :src="
                        item.marker.icon
                          ? item.marker.icon.url
                          : '/img/google-map-marker.png'
                      "
                      class="rounded h-30px"
                    />
                  </td>
                  <td>{{ item.marker.name }}</td>
                  <td>{{ item.marker.type }}</td>
                  <td>{{ item.marker.subType }}</td>
                  <td>
                    <div class="d-flex d-inline-flex">
                      <div
                        class="
                          widget-icon
                          rounded
                          bg-success
                          me-5px
                          mb-5px
                          text-white
                        "
                        @click="saveItem(index, itemsPolygons)"
                      >
                        <i class="fa fa-save"></i>
                      </div>
                      <div
                        class="
                          widget-icon
                          rounded
                          bg-gray
                          me-5px
                          mb-5px
                          text-white
                        "
                        @click="openModalEditMap(index, itemsPolygons)"
                      >
                        <i class="fa fa-map-marked-alt"></i>
                      </div>
                      <div
                        class="
                          widget-icon
                          rounded
                          bg-primary
                          me-5px
                          mb-5px
                          text-white
                        "
                        @click="showModal(index, itemsPolygons, 'polygon' )"
                      >
                        <i class="fa fa-edit"></i>
                      </div>
                      <b-modal
                        :id="`modal-edit-polygon-${index}`"
                        :title="item.marker.name"
                        @ok="editar(index, itemsPolygons)"
                      >
                        <textarea
                          cols="50"
                          rows="10"
                          v-model="itemsPolygons[index].prettyJSON"
                        ></textarea>
                      </b-modal>
                      <div
                        class="
                          widget-icon
                          rounded
                          bg-danger
                          me-5px
                          mb-5px
                          text-white
                        "
                        @click="borrar(index, itemsPolygons)"
                      >
                        <i class="fa fa-trash"></i>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
        <!-- END tab-pane -->
        <!-- BEGIN tab-pane -->
        <b-tab>
          <template slot="title">
            <i class="far fa-fw fa-lg fa-circle"></i>
            <span class="d-none d-xl-inline"> Círculos</span>
          </template>
          <span class="badge bg-success fs-10px nuevo float-end p-2" @click="openModalNew('circle')"
              >Nuevo</span
            >
          <h4 class="mb-1"><b>Círculos</b></h4>
          <p class="mb-3">Círculos dibujados en el mapa</p>
          <!-- BEGIN table-responsive -->
          <div class="table-responsive">
            <table
              class="table table-striped mb-0 align-middle table-condensed"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nombre</th>
                  <th>Tipo</th>
                  <th>Subtipo</th>
                  <th width="1%"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in itemsCircles"
                  :key="'itemCircle-' + index"
                >
                  <td>
                    <img
                      :src="
                        item.marker.icon
                          ? item.marker.icon.url
                          : '/img/google-map-marker.png'
                      "
                      class="rounded h-30px"
                    />
                  </td>
                  <td>{{ item.marker.name }}</td>
                  <td>{{ item.marker.type }}</td>
                  <td>{{ item.marker.subType }}</td>
                  <td>
                    <div class="d-flex d-inline-flex">
                      <div
                        class="
                          widget-icon
                          rounded
                          bg-success
                          me-5px
                          mb-5px
                          text-white
                        "
                        @click="saveItem(index, itemsCircles)"
                      >
                        <i class="fa fa-save"></i>
                      </div>
                      <div
                        class="
                          widget-icon
                          rounded
                          bg-gray
                          me-5px
                          mb-5px
                          text-white
                        "
                        @click="openModalEditMap(index, itemsCircles)"
                      >
                        <i class="fa fa-map-marked-alt"></i>
                      </div>
                      <div
                        class="
                          widget-icon
                          rounded
                          bg-primary
                          me-5px
                          mb-5px
                          text-white
                        "
                        @click="showModal(index, itemsCircles, 'circle' )"
                      >
                        <i class="fa fa-edit"></i>
                      </div>
                      <b-modal
                        :id="`modal-edit-circle-${index}`"
                        :title="item.marker.name"
                        @ok="editar(index, itemsCircles)"
                      >
                        <textarea
                          cols="50"
                          rows="10"
                          v-model="itemsCircles[index].prettyJSON"
                        ></textarea>
                      </b-modal>
                      <div
                        class="
                          widget-icon
                          rounded
                          bg-danger
                          me-5px
                          mb-5px
                          text-white
                        "
                        @click="borrar(index, itemsCircles)"
                      >
                        <i class="fa fa-trash"></i>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
        <!-- END tab-pane -->
        <!-- BEGIN tab-pane -->
        <b-tab>
          <template slot="title">
            <i class="far fa-fw fa-lg fa-square-full"></i>
            <span class="d-none d-xl-inline"> Rectángulo</span>
          </template>
          <span class="badge bg-success fs-10px nuevo float-end p-2" @click="openModalNew('rectangle')"
              >Nuevo</span
            >
          <h4 class="mb-1"><b>Rectángulo</b></h4>
          <p class="mb-3">Rectángulo dibujados en el mapa</p>
          <!-- BEGIN table-responsive -->
          <div class="table-responsive">
            <table
              class="table table-striped mb-0 align-middle table-condensed"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nombre</th>
                  <th>Tipo</th>
                  <th>Subtipo</th>
                  <th width="1%"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in itemsRectangles"
                  :key="'itemRectangle-' + index"
                >
                  <td>
                    <img
                      :src="
                        item.marker.icon
                          ? item.marker.icon.url
                          : '/img/google-map-marker.png'
                      "
                      class="rounded h-30px"
                    />
                  </td>
                  <td>{{ item.marker.name }}</td>
                  <td>{{ item.marker.type }}</td>
                  <td>{{ item.marker.subType }}</td>
                  <td>
                    <div class="d-flex d-inline-flex">
                      <div
                        class="
                          widget-icon
                          rounded
                          bg-success
                          me-5px
                          mb-5px
                          text-white
                        "
                        @click="saveItem(index, itemsRectangles)"
                      >
                        <i class="fa fa-save"></i>
                      </div>
                      <div
                        class="
                          widget-icon
                          rounded
                          bg-gray
                          me-5px
                          mb-5px
                          text-white
                        "
                        @click="openModalEditMap(index, itemsRectangles)"
                      >
                        <i class="fa fa-map-marked-alt"></i>
                      </div>
                      <div
                        class="
                          widget-icon
                          rounded
                          bg-primary
                          me-5px
                          mb-5px
                          text-white
                        "
                        @click="showModal(index, itemsRectangles, 'rectangle' )"
                      >
                        <i class="fa fa-edit"></i>
                      </div>
                      <b-modal
                        :id="`modal-edit-rectangle-${index}`"
                        :title="item.marker.name"
                        @ok="editar(index, itemsRectangles)"
                      >
                        <textarea
                          cols="50"
                          rows="10"
                          v-model="itemsRectangles[index].prettyJSON"
                        ></textarea>
                      </b-modal>
                      <div
                        class="
                          widget-icon
                          rounded
                          bg-danger
                          me-5px
                          mb-5px
                          text-white
                        "
                        @click="borrar(index, itemsRectangles)"
                      >
                        <i class="fa fa-trash"></i>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
        <!-- END tab-pane -->
        <!-- BEGIN tab-pane -->
        <b-tab>
          <template slot="title">
            <i class="far fa-fw fa-lg fa-square"></i>
            <span class="d-none d-xl-inline"> Botones</span>
          </template>
          <span class="badge bg-success fs-10px nuevo float-end p-2" @click="openModalNew('button')"
              >Nuevo</span
            >
          <h4 class="mb-1"><b>Botones</b></h4>
          <p class="mb-3">Botones de control</p>
          <!-- BEGIN table-responsive -->
          <div class="table-responsive">
            <table
              class="table table-striped mb-0 align-middle table-condensed"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nombre</th>
                  <th>Tipo</th>
                  <th>Subtipo</th>
                  <th width="1%"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in itemsButtons"
                  :key="'itemButton-' + index"
                >
                  <td>
                    <img
                      :src="
                        item.marker.icon
                          ? item.marker.icon.url
                          : '/img/google-map-marker.png'
                      "
                      class="rounded h-30px"
                    />
                  </td>
                  <td>{{ item.marker.name }}</td>
                  <td>{{ item.marker.type }}</td>
                  <td>{{ item.marker.subType }}</td>
                  <td>
                    <div class="d-flex d-inline-flex">
                      <div
                        class="
                          widget-icon
                          rounded
                          bg-success
                          me-5px
                          mb-5px
                          text-white
                        "
                        @click="saveItem(index)"
                      >
                        <i class="fa fa-save"></i>
                      </div>
                      <div
                        class="
                          widget-icon
                          rounded
                          bg-gray
                          me-5px
                          mb-5px
                          text-white
                        "
                        @click="openModalEditMap(index)"
                      >
                        <i class="fa fa-map-marked-alt"></i>
                      </div>
                      <div
                        class="
                          widget-icon
                          rounded
                          bg-primary
                          me-5px
                          mb-5px
                          text-white
                        "
                        @click="showModal(index)"
                      >
                        <i class="fa fa-edit"></i>
                      </div>
                      <b-modal
                        :id="`modal-edit-${index}`"
                        :title="item.marker.name"
                        @ok="editar(index)"
                      >
                        <textarea
                          cols="50"
                          rows="10"
                          v-model="items[index].prettyJSON"
                        ></textarea>
                      </b-modal>
                      <div
                        class="
                          widget-icon
                          rounded
                          bg-danger
                          me-5px
                          mb-5px
                          text-white
                        "
                        @click="borrar(index)"
                      >
                        <i class="fa fa-trash"></i>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
        <!-- END tab-pane -->
        <!-- BEGIN tab-pane -->
        <b-tab>
          <template slot="title">
            <i class="fa fa-fw fa-lg fa-retweet"></i>
            <span class="d-none d-xl-inline"> Selectores</span>
          </template>
          <span class="badge bg-success fs-10px nuevo float-end p-2" @click="openModalNew('selector')"
              >Nuevo</span
            >
          <h4 class="mb-1"><b>Selectores</b></h4>
          <p class="mb-3">Selectores de control</p>
          <!-- BEGIN table-responsive -->
          <div class="table-responsive">
            <table
              class="table table-striped mb-0 align-middle table-condensed"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nombre</th>
                  <th>Tipo</th>
                  <th>Subtipo</th>
                  <th width="1%"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in itemsSelectors"
                  :key="'itemSlector-' + index"
                >
                  <td>
                    <img
                      :src="
                        item.marker.icon
                          ? item.marker.icon.url
                          : '/img/google-map-marker.png'
                      "
                      class="rounded h-30px"
                    />
                  </td>
                  <td>{{ item.marker.name }}</td>
                  <td>{{ item.marker.type }}</td>
                  <td>{{ item.marker.subType }}</td>
                  <td>
                    <div class="d-flex d-inline-flex">
                      <div
                        class="
                          widget-icon
                          rounded
                          bg-success
                          me-5px
                          mb-5px
                          text-white
                        "
                        @click="saveItem(index)"
                      >
                        <i class="fa fa-save"></i>
                      </div>
                      <div
                        class="
                          widget-icon
                          rounded
                          bg-gray
                          me-5px
                          mb-5px
                          text-white
                        "
                        @click="openModalEditMap(index)"
                      >
                        <i class="fa fa-map-marked-alt"></i>
                      </div>
                      <div
                        class="
                          widget-icon
                          rounded
                          bg-primary
                          me-5px
                          mb-5px
                          text-white
                        "
                        @click="showModal(index)"
                      >
                        <i class="fa fa-edit"></i>
                      </div>
                      <b-modal
                        :id="`modal-edit-${index}`"
                        :title="item.marker.name"
                        @ok="editar(index)"
                      >
                        <textarea
                          cols="50"
                          rows="10"
                          v-model="items[index].prettyJSON"
                        ></textarea>
                      </b-modal>
                      <div
                        class="
                          widget-icon
                          rounded
                          bg-danger
                          me-5px
                          mb-5px
                          text-white
                        "
                        @click="borrar(index)"
                      >
                        <i class="fa fa-trash"></i>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
        <!-- END tab-pane -->
        <!-- BEGIN tab-pane -->
        <b-tab>
          <template slot="title">
            <i class="fa fa-fw fa-lg fa-tags"></i>
            <span class="d-none d-xl-inline"> Indicadores</span>
          </template>
          <span class="badge bg-success fs-10px nuevo float-end p-2" @click="openModalNew('indicator')"
              >Nuevo</span
            >
          <h4 class="mb-1"><b>Indicadores</b></h4>
          <p class="mb-3">Indicadores de control</p>
          <!-- BEGIN table-responsive -->
          <div class="table-responsive">
            <table
              class="table table-striped mb-0 align-middle table-condensed"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nombre</th>
                  <th>Tipo</th>
                  <th>Subtipo</th>
                  <th width="1%"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in itemsIndicators"
                  :key="'itemIndicator-' + index"
                >
                  <td>
                    <img
                      :src="
                        item.marker.icon
                          ? item.marker.icon.url
                          : '/img/google-map-marker.png'
                      "
                      class="rounded h-30px"
                    />
                  </td>
                  <td>{{ item.marker.name }}</td>
                  <td>{{ item.marker.type }}</td>
                  <td>{{ item.marker.subType }}</td>
                  <td>
                    <div class="d-flex d-inline-flex">
                      <div
                        class="
                          widget-icon
                          rounded
                          bg-success
                          me-5px
                          mb-5px
                          text-white
                        "
                        @click="saveItem(index)"
                      >
                        <i class="fa fa-save"></i>
                      </div>
                      <div
                        class="
                          widget-icon
                          rounded
                          bg-gray
                          me-5px
                          mb-5px
                          text-white
                        "
                        @click="openModalEditMap(index)"
                      >
                        <i class="fa fa-map-marked-alt"></i>
                      </div>
                      <div
                        class="
                          widget-icon
                          rounded
                          bg-primary
                          me-5px
                          mb-5px
                          text-white
                        "
                        @click="showModal(index)"
                      >
                        <i class="fa fa-edit"></i>
                      </div>
                      <b-modal
                        :id="`modal-edit-${index}`"
                        :title="item.marker.name"
                        @ok="editar(index)"
                      >
                        <textarea
                          cols="50"
                          rows="10"
                          v-model="items[index].prettyJSON"
                        ></textarea>
                      </b-modal>
                      <div
                        class="
                          widget-icon
                          rounded
                          bg-danger
                          me-5px
                          mb-5px
                          text-white
                        "
                        @click="borrar(index)"
                      >
                        <i class="fa fa-trash"></i>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
        <!-- END tab-pane -->
      </div>
    </b-tabs>
    <panel>
      <template slot="header">
        <h4 class="panel-title">Elementos</h4>
      </template>
      <div>
        <b-modal
          id="modal-map"
          size="lg"
          title="Edición de marcado o figura"
          @ok="aceptar"
        >
          <div id="map" style="height: 400px">
            <GmapMap
              ref="mapEdit"
              :center="{ lat: 0, lng: 0 }"
              :zoom="10"
              class="w-100 h-100"
            >
            </GmapMap>
          </div>
        </b-modal>
        <b-modal id="modal-edit-new" title="Nuevo marcador" @ok="agregar">
          <textarea
            ref="textarea"
            cols="50"
            rows="10"
            v-model="editedItem.prettyJSON"
          ></textarea>
        </b-modal>
        <!-- END table-responsive -->
      </div>
      <!-- BEGIN hljs-wrapper -->
      <div class="hljs-wrapper" v-highlight slot="outsideBody"></div>
      <!-- END hljs-wrapper -->
    </panel>
    <!-- END panel -->
    <notifications
      group="bottom-center"
      position="bottom center"
      :speed="500"
    />
  </div>
</template>

<script>
import { gmapApi } from "vue2-google-maps";
import { mapGetters } from "vuex";
import { drawRawItem } from "@/helpers/maps";
import { getItems, newItem, updateItem, deleteItem } from "@/helpers/markers";

export default {
  name: "MapControls",
  data() {
    return {
      items: [],
      map: null,
      selected: -1,
      editedItem: {
        prettyJSON: "",
        marker: null,
      },
      control: {
        type: "marker",
        subType: "",
        name: "",
        reg: "",
        writeReg: "",
        auxRegs: "",
        action: "none",
        onColor: "lime",
        offColor: "gray",
        onText: "",
        offText: "",
        onClass: "text-white",
        offClass: "text-white",
        preText: "",
        postText: "",
        classes: "text-white",
        position: { lat: 0, lng: 0 },
        label: {
          text: "Marker",
          color: "#ffffff",
          fontWeight: "bold",
        },
        path: [],
        bounds: {
          north: 0,
          south: 0,
          east: 0,
          west: 0,
        },
        center: { lat: 0, lng: 0 },
        radius: 0,
        icon: {
          url: "/img/google-map-marker.png",
          labelOrigin: {
            x: 30,
            y: 70,
          },
          size: {
            width: 60,
            height: 60,
          },
          anchor: {
            x: 30,
            y: 60,
          },
        },
      },
    };
  },
  created() {},
  mounted() {
    localStorage.setItem("screen", "map-controls");
    if (this.currentDevice) this.loadItems();
  },
  methods: {
    getItems,
    newItem,
    updateItem,
    deleteItem,
    initMap(item) {
      if (this.currentDevice) {
        this.$refs.mapEdit.$mapPromise.then((map) => {
          const options = {
            zoom: this.currentDevice.position.zoom,
          };
          map.setOptions(options);
          map.panTo(this.currentDevice.position);
          this.map = map;

          if (item) {
            this.items.forEach((i) => {
              const mapMarker = drawRawItem(
                i.marker,
                this.google,
                map,
                item.id == i.id
              );
              if (item.id == i.id) item.mapMarker = mapMarker;
            });
          } else {
            item = this.editedItem;
            const drawingModes = [];
            let drawingMode = null;
            if(item.marker.type == 'marker') {drawingModes.push(   this.google.maps.drawing.OverlayType.MARKER); drawingMode = this.google.maps.drawing.OverlayType.MARKER;} 
            if(item.marker.type == 'polyline'){drawingModes.push(this.google.maps.drawing.OverlayType.POLYLINE); drawingMode = this.google.maps.drawing.OverlayType.POLYLINE;}
            if(item.marker.type == 'polygon'){drawingModes.push(  this.google.maps.drawing.OverlayType.POLYGON); drawingMode = this.google.maps.drawing.OverlayType.POLYGON;}  
            if(item.marker.type == 'circle') {drawingModes.push(   this.google.maps.drawing.OverlayType.CIRCLE); drawingMode = this.google.maps.drawing.OverlayType.CIRCLE;}  
            if(item.marker.type == 'rectangle'){drawingModes.push(this.google.maps.drawing.OverlayType.RECTANGLE); drawingMode = this.google.maps.drawing.OverlayType.RECTANGLE;}

            const drawingManager = new this.google.maps.drawing.DrawingManager({
              drawingMode,
              drawingControl: true,
              drawingControlOptions: {
                position: this.google.maps.ControlPosition.TOP_CENTER,
                drawingModes
              },
              markerOptions: {
                clickable: true,
                editable: true,
                draggable: true,
                icon: "/img/google-map-marker.png",
              },
              circleOptions: {
                clickable: true,
                editable: true,
                draggable: true,
              },
              polygonOptions: {
                clickable: true,
                editable: true,
                draggable: true,
              },
              rectangleOptions: {
                clickable: true,
                editable: true,
                draggable: true,
              },
            });
            drawingManager.addListener("polygoncomplete", (polygon) => {
              if (item.mapMarker) item.mapMarker.setMap(null);
              const path = polygon.getPath();
              const points = [];
              for (let i = 0; i < path.getLength(); i++) {
                points.push({
                  lat: path.getAt(i).lat(),
                  lng: path.getAt(i).lng(),
                });
              }
              item.marker.type = "polygon";
              item.marker.path = points;
              item.prettyJSON = JSON.stringify(item.marker, null, 4);
              item.mapMarker = polygon;
            });
            drawingManager.addListener("markercomplete", (marker) => {
              if (item.mapMarker) item.mapMarker.setMap(null);
              const position = marker.getPosition();
              item.marker.position = {
                lat: position.lat(),
                lng: position.lng(),
              };
              item.marker.type = "marker";
              item.prettyJSON = JSON.stringify(item.marker, null, 4);
              item.mapMarker = marker;
            });
            drawingManager.addListener("circlecomplete", (circle) => {
              if (item.mapMarker) item.mapMarker.setMap(null);
              const center = circle.getCenter();
              const radius = circle.getRadius();
              item.marker.center = {
                lat: center.lat(),
                lng: center.lng(),
              };
              item.marker.radius = radius;
              item.marker.type = "circle";
              item.prettyJSON = JSON.stringify(item.marker, null, 4);
              item.mapMarker = circle;
            });

            drawingManager.addListener("rectanglecomplete", (rectangle) => {
              if (item.mapMarker) item.mapMarker.setMap(null);
              const bounds = rectangle.getBounds();
              const ne = bounds.getNorthEast();
              const sw = bounds.getSouthWest();
              const north = ne.lat();
              const south = sw.lat();
              const east = ne.lng();
              const west = sw.lng();
              item.marker.bounds = {
                north: north,
                south: south,
                east: east,
                west: west,
              };
              item.marker.type = "rectangle";
              item.prettyJSON = JSON.stringify(item.marker, null, 4);
              item.mapMarker = rectangle;
            });
            drawingManager.setMap(map);
          }
        });
      }
    },
    aceptar() {
      console.log(this.editedItem);
      if (this.selected < 0) {
        this.$bvModal.show("modal-edit-new");
        setTimeout(this.setPrettyJSON, 1000);
      } else {
        console.log(this.items[this.selected].marker.type);
        switch (this.items[this.selected].marker.type) {
          case "marker":
            this.items[this.selected].marker.position = this.items[
              this.selected
            ].mapMarker
              .getPosition()
              .toJSON();
            break;
          case "circle":
            this.items[this.selected].marker.center = this.items[
              this.selected
            ].mapMarker
              .getCenter()
              .toJSON();
            this.items[this.selected].marker.radius = this.items[
              this.selected
            ].mapMarker
              .getRadius();
            break;
          case "polygon":
            break;
          case "rectangle":
            break;

          default:
            break;
        }
      }
      this.selected = -1;
    },
    editar(index, items) {
      try {
        items[index].marker = JSON.parse(items[index].prettyJSON);
        this.$notify({
          group: "bottom-center",
          title: "¡Exito!",
          text: "Propiedades modificadas",
          type: "success",
        });
      } catch (error) {
        console.error(error);
        this.$notify({
          group: "bottom-center",
          title: "Error",
          message: "El formato del marcador no es correcto",
          type: "danger",
        });
      }
    },
    agregar() {
      try {
        this.editedItem.marker = JSON.parse(this.editedItem.prettyJSON);
        delete this.editedItem.mapMarker;
        this.newItem(this.editedItem)
          .then((item) => {
            this.items.unshift(item.marker);
            this.$notify({
              group: "bottom-center",
              title: `¡Operación exitosa!`,
              text: `El elemento ha sido agregado`,
              type: "success",
            });
            console.log(item);
          })
          .catch((err) => {
            console.log(err);
            this.$notify({
              group: "bottom-center",
              title: `¡Algo fué mal en la operación!`,
              text: `El elemento no ha sido agregado`,
              type: "danger",
            });
          });
      } catch (error) {
        console.error(error);
      }
    },
    setPrettyJSON() {
      this.$refs.textarea.value = JSON.stringify(
        this.editedItem.marker,
        null,
        4
      );
    },
    showModal(index, items, type) {
      this.selected = index;
      this.editedItem = items[index];
      this.$bvModal.show(`modal-edit-${type}-${index}`);
    },
    openModalEditMap(index, items) {
      this.selected = index;
      this.$bvModal.show("modal-map");
      this.editedItem = items[index];
      setTimeout(this.initMap, 1000, items[index]);
    },
    openModalNew(type) {
      
      this.editedItem = {
        device: this.currentDevice.id,
        marker: { ...this.control, type },
      };
      if(['marker','circle','polygon','rectangle'].includes(type)){
        this.$bvModal.show("modal-map");
        setTimeout(this.initMap, 1000);
      }else{
        this.$bvModal.show("modal-edit-new");
        setTimeout(this.setPrettyJSON, 1000);
      }
    },
    loadItems() {
      this.getItems(this.currentDevice)
        .then((data) => {
          if (data.ok) {
            this.items = data.markers.map((marker) => {
              return {
                ...marker,
                prettyJSON: JSON.stringify(
                  { ...this.control, ...marker.marker },
                  null,
                  4
                ),
              };
            });
          }
        })
        .catch(console.error);
    },
    saveItem(index, items) {
      try {
        delete items[index].mapMarker;
        this.updateItem(items[index])
          .then((item) => {
            this.$notify({
              group: "bottom-center",
              title: `¡Operación exitosa!`,
              text: `El elemento ha sido modificado`,
              type: "success",
            });
            console.log(item);
          })
          .catch((err) => {
            console.log(err);
            this.$notify({
              group: "bottom-center",
              title: `¡Algo fué mal en la operación!`,
              text: `El elemento no ha sido modificado`,
              type: "danger",
            });
          });
      } catch (error) {
        console.error(error);
      }
    },
    borrar(index, items) {
      this.$bvModal
        .msgBoxConfirm("¿Borrar el marcador?")
        .then((value) => {
          if (value) {
            this.deleteItem(items[index])
              .then((data) => {
                if (data.ok) {
                  this.loadItems();
                  this.$notify({
                    group: "bottom-center",
                    title: `¡Operación exitosa!`,
                    text: `El elemento ha sido borrado`,
                    type: "success",
                  });
                }
              })
              .catch((error) => {
                console.error(error);
                this.$notify({
                  group: "bottom-center",
                  title: `¡Algo fué mal en la operación!`,
                  text: `El elemento no ha sido borrado`,
                  type: "danger",
                });
              });
          }
        })
        .catch(console.error);
    },
  },
  computed: {
    ...mapGetters("devices", ["currentDevice", "getValue", "bufferChanged"]),
    ...mapGetters("auth", ["currentUser"]),
    itemsMarkers() {
      return this.items.filter((item) => item.marker.type === "marker" || item.marker.type === "label");
    },
    itemsCircles() {
      return this.items.filter((item) => (item.marker.type === "circle" || item.marker.type === "pivot"));
    },
    itemsPolygons() {
      return this.items.filter((item) => item.marker.type === "polygon");
    },
    itemsRectangles() {
      return this.items.filter((item) => item.marker.type === "rectangle");
    },
    itemsButtons() {
      return this.items.filter((item) => item.marker.type === "button");
    },
    itemsSelectors() {
      return this.items.filter((item) => item.marker.type === "selector");
    },
    itemsIndicators() {
      return this.items.filter((item) => item.marker.type === "indicator");
    },
    finca: function () {
      if (!this.currentDevice) return "";
      return this.currentDevice.name
        ? this.currentDevice.name
        : '<i class="fas fa-spinner fa-spin"></i>';
    },
    google: gmapApi,
  },
  watch: {
    currentDevice: function () {
      if (this.currentDevice) this.loadItems();
    },
  },
};
</script>

<style scoped>
.widget-icon,
.nuevo {
  cursor: pointer;
}
textarea {
  border: 1px inset #ccc;
  background-color: white;
  font: small courier, monospace black;
  width: 100%;
  height: 300px; /* or whatever measurements you want */
  overflow: auto; /* in most browsers, this will results in a scrollbar when there's more content than can fit in the dimensions defined above */
}
</style>
