<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Inicio</a></li>
      <li class="breadcrumb-item active">
        <a href="javascript:;">variables</a>
      </li>
    </ol>
    <!-- END breadcrumb -->
    <!-- BEGIN page-header -->
    <h1 class="page-header">
      Tabla de variables <small>{{ finca }}</small>
    </h1>
    <!-- END page-header -->

    <panel bodyClass="p-0" class="" noButton="true">
      <template slot="header">
        <h4 class="panel-title">Lista de variables</h4>
      </template>
      <span class="fa-stack fa-2x text-primary m-2">
        <i class="fa fa-file me-2" @click="insertar"></i>
        <i class="fa fa-save" @click="guardarTodo"></i>
      </span>

      <vue-good-table
        :columns="columns"
        :rows="rows"
        :lineNumbers="true"
        
        :selectOptions="{
          enabled: true,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
          selectionText: 'filas seleccionadas',
          clearSelectionText: 'limpiar',
        }"
        @on-selected-rows-change="selectionChanged"
      >
        >
        <div slot="selected-row-actions">
          <button class="btn btn-danger me-2" @click="borrar">
            <i class="fa fa-trash"></i>
          </button>
          <button class="btn btn-primary me-2" @click="copiar">
            <i class="fa fa-copy"></i>
          </button>
          <button class="btn btn-primary me-2" @click="pegar">
            <i class="fa fa-paste"></i>
          </button>
          <button class="btn btn-success" @click="guardar">
            <i class="fa fa-save"></i>
          </button>
        </div>
        <template slot="table-row" slot-scope="props">
          <!-- @keyup="changeField(props, props.column.field, $event)" -->
          <input
            v-if="
              ['name', 'description', 'address'].includes(props.column.field)
            "
            type="text"
            class="form-control form-control-sm"
            v-model="rows[props.index][props.column.field]"
          />
          <input
            v-else-if="
              ['offset', 'gain', 'DB_Interval'].includes(props.column.field)
            "
            type="number"
            step="0.01"
            class="form-control form-control-sm"
            v-model="rows[props.index][props.column.field]"
          />
          <input
            v-else-if="['dataBase'].includes(props.column.field)"
            class="form-check-input"
            type="checkbox"
            v-model="rows[props.index][props.column.field]"
          />
          <b-form-select
            v-else-if="['access'].includes(props.column.field)"
            v-model="rows[props.index][props.column.field]"
            :options="[
              { value: 'read/write', text: 'read/write' },
              { value: 'read', text: 'read' },
              { value: 'write', text: 'write' },
            ]"
            class="form-select form-select-sm"
          ></b-form-select>
          <b-form-select
            v-else-if="['dataType'].includes(props.column.field)"
            v-model="rows[props.index][props.column.field]"
            :options="[
              { value: 'int', text: 'int' },
              { value: 'uint', text: 'uint' },
              { value: 'long', text: 'long' },
              { value: 'ulong', text: 'ulong' },
              { value: 'float', text: 'float' },
              { value: 'bool', text: 'bool' },
            ]"
            class="form-select form-select-sm"
          ></b-form-select>
          <input
            v-else-if="['value'].includes(props.column.field)"
            class="form-control form-control-sm"
            type="number"
            step="0.1"
            :ref="'value'+props.index"
          />
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </panel>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "Variables",
  components: {},
  data() {
    return {
      columns: [
        { label: "id", field: "id", hidden: true },
        { label: "device_id", field: "device_id", hidden: true },
        {
          label: "Nombre",
          field: "name",
          thClass: "text-nowrap",
          tdClass: "text-nowrap",
        },
        {
          label: "Descripción",
          field: "description",
          thClass: "text-nowrap",
          tdClass: "text-nowrap",
        },
        {
          label: "Dirección",
          field: "address",
          thClass: "text-nowrap",
          tdClass: "text-nowrap",
        },
        {
          label: "Tipo",
          field: "dataType",
          thClass: "text-nowrap",
          tdClass: "text-nowrap",
        },
        {
          label: "Acceso",
          field: "access",
          thClass: "text-nowrap",
          tdClass: "text-nowrap",
        },
        {
          label: "Offset",
          field: "offset",
          type: "number",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Gain",
          field: "gain",
          type: "number",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "DB",
          field: "dataBase",
          width: "5%",
          thClass: "text-nowrap",
          tdClass: "text-nowrap",
        },
        {
          label: "Interval",
          field: "DB_Interval",
          type: "number",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Valor",
          field: "value",
          type: "number",
          thClass: "text-center",
          tdClass: "text-center",
        },
        /* {
          label: "Acciones",
          field: "actions",
          type: "actions",
          tdClass: "text-center valign-middle text-nowrap",
          thClass: "text-center text-nowrap",
        }, */
      ],
      rows: [],
      nueva: {
        id: "",
        device: "",
        name: "nueva",
        description: "",
        address: "r_0",
        dataType: "int",
        access: "read",
        offset: 0,
        gain: 1,
        dataBase: false,
        DB_Interval: 300,
      },
      copy: [],
      changed: [],
    };
  },
  mounted() {
    this.rows = this.variables;
    this.getVariables({ id: this.currentDevice.id, user: this.currentUser });
    localStorage.setItem("screen", "variables");
  },
  methods: {
    ...mapActions("devices", [
      "getVariables",
      "newVariable",
      "updateVariable",
      "deleteVariable",
      "getValue",
    ]),
    ...mapMutations("devices", ["addVariable"]),
    selectionChanged(selectedRows) {
      this.changed = selectedRows;
    },
    insertar() {
      this.newVariable({
        variable: { ...this.nueva, device: this.currentDevice.id },
        user: this.currentUser,
      });
    },
    borrar() {
      this.changed.selectedRows.forEach((row) => {
        this.rows.splice(row.originalIndex, 1);
      });
      this.changed = [];
    },
    copiar() {
      this.copy = this.changed.selectedRows;
    },
    pegar() {
      /* eslint-disable */
      this.copy.forEach((row) => {
        // eslint-disable-line no-unused-vars
        const { originalIndex, vgt_id, ...nRow } = row;
        this.newVariable({
          variable: { ...nRow, device: this.currentDevice.id },
          user: this.currentUser,
        });
      });
      /* eslint-enable */
    },
    guardar() {
      this.changed.selectedRows.forEach((row) => {
        this.updateVariable({
          variable: {
            ...this.rows[row.originalIndex],
            device: this.currentDevice.id,
          },
          user: this.currentUser,
        });
        //this.rows.splice(this.rows.indexOf(row), 1);
      });
    },
    guardarTodo() {
      this.rows.forEach((row) => {
        console.log({ ...row, device: this.currentDevice.id });
        this.updateVariable({
          variable: {
            ...this.row,
            device: this.currentDevice.id,
          },
          user: this.currentUser,
        });
        //this.rows.splice(this.rows.indexOf(row), 1);
      });
    },
    updateValues() {
      //console.log(this.$refs)
      this.rows.forEach((row, index) => {
        this.getValue(row).then((value) => {
          //this.rows[index].value = value;
          this.$refs[`value${index}`].value = value;
        })
        .catch( (e) =>{
          console.log(e);
        });
      });
    },
  },
  computed: {
    ...mapGetters("devices", ["currentDevice", "variables", "bufferChanged", "buffer"]),
    ...mapGetters("auth", ["currentUser"]),
    finca: function () {
      if (!this.currentDevice) return '<i class="fas fa-spinner fa-spin"></i>';
      return this.currentDevice.name
        ? this.currentDevice.name
        : '<i class="fas fa-spinner fa-spin"></i>';
    },
  },
  watch: {
    variables: function () {
      this.rows = this.variables;
    },
    bufferChanged: function () {
      this.updateValues();
    },
    buffer: function () {
      //console.log(this.buffer)
    },
  },
};
</script>
