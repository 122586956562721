<template>
  <div class="col-xl-8 col-lg-6">
    <!-- BEGIN card -->
    <div class="card border-0 mb-3 bg-gray-800 text-white">
      <div class="card-body">
        <div class="mb-3 text-gray-500">
          <b>VISITORS ANALYTICS</b>
          <span class="ms-2"
            ><i
              class="fa fa-info-circle"
              title="Top products with units sold"
              v-b-popover.hover="
                'Products with the most individual units sold. Includes orders from all sales channels.'
              "
            ></i
          ></span>
        </div>
      </div>
      <div class="card-body p-0">
        <div style="height: 280px">
          <div class="dark-mode overflow-hidden" style="height: 270px">
            <vn-bar
              :model="barChart.data"
              textField="label"
              valueField="value"
              :staggerLabels="barChart.staggerLabels"
              x-format=",.1f"
              y-format=",.1f"
            >
            </vn-bar>
          </div>
        </div>
      </div>
    </div>
    <!-- END card -->
  </div>
</template>

<script>
import AppOptions from "@/config/AppOptions.vue";
export default {
  name: "ColumnChart",
  data() {
    var barChartData = [
      {
        key: "Cumulative Return",
        values: [
          { label: "A", value: 29, color: AppOptions.color.red },
          { label: "B", value: 15, color: AppOptions.color.orange },
          { label: "C", value: 32, color: AppOptions.color.teal },
          { label: "D", value: 196, color: AppOptions.color.cyan },
          { label: "E", value: 44, color: AppOptions.color.blue },
          { label: "F", value: 98, color: AppOptions.color.purple },
          { label: "G", value: 13, color: AppOptions.color["gray500"] },
          { label: "H", value: 5, color: AppOptions.color.componentColor },
        ],
      },
    ];
    return {
      barChart: {
        data: barChartData,
        staggerLabels: false,
      },
    };
  },
};
</script>

<style></style>
