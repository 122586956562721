const openWeatherQueryUrl = "https://api.openweathermap.org/data/2.5/";
const apiKey = process.env.VUE_APP_OPEN_WEATHER_API;
const meses = [
  "ene",
  "feb",
  "mar",
  "abr",
  "may",
  "jun",
  "jul",
  "ago",
  "sep",
  "oct",
  "nov",
  "dic",
];

const getCurrentWeather = (position) => {
  // For temperature in Fahrenheit and wind speed in miles/hour use units=imperial
  const url =
    openWeatherQueryUrl +
    "weather?lat=" +
    position.lat +
    "&lon=" +
    position.lng +
    "&appid=" +
    apiKey +
    "&units=metric";
  return new Promise((resolve, reject) => {
    fetch(url)
      // First we need to check that the response status is 200 before parsing the response as JSON.
      .then(function (response) {
        if (!response.ok) {
          //console.log("There is an issue. Status Code: " + response.status);
          // alert invalid search to user
          reject(
            "No results for " +
              position.lat +
              ", " +
              position.lng +
              " perhaps you mistyped... Please try again"
          );
          return;
        } else {
          return response.json(); // we only get here if there is no error
        }
      })
      .then(function (weatherData) {
        /* console.log("Here is the object containing the current weather data");
        console.log(weatherData);
        console.log("------------------------------------------------"); */
        resolve(weatherData);
      })
      .catch(function (error) {
        //console.log("There is an error: " + error);
        reject(error);
      });
  });
};

const getForecast = (position) => {
  // For temperature in Fahrenheit and wind speed in miles/hour use units=imperial
  var url =
    openWeatherQueryUrl +
    "forecast?lat=" +
    position.lat +
    "&lon=" +
    position.lng +
    "&appid=" +
    apiKey +
    "&units=metric";
  return new Promise((resolve, reject) => {
    fetch(url)
      // First we need to check that the response status is 200 before parsing the response as JSON.
      .then(function (response) {
        if (!response.ok) {
          reject("There is an issue. Status Code: " + response.status);
          return;
        } else {
          return response.json(); // we only get here if there is no error
        }
      })
      .then(function (forecastData) {
        /* console.log("Here is the object containing the forcast data");
        console.log(forecastData); */
        // define forecastObject variable multiple times and have different property values each iteration of the loop.
        // declarare empty array
        var ourForecastObject = [];
        // iterate throughout the returned object to make an array of objects called "forecastObject" that contains only the data we want to append to the page
        let day = 0;
        let min = 100;
        let max = -100;
        for (var i = 0; i < forecastData.list.length; i++) {
          // if array iteration is divisible by 8
          let d = getDay(forecastData.list[i].dt_txt);

          if (d != day) {
            day = d;
            min = 100;
            max = -100;
          }
          if (forecastData.list[i].main.temp_max > max)
            max = forecastData.list[i].main.temp_max;
          if (forecastData.list[i].main.temp_min < min)
            min = forecastData.list[i].main.temp_min;
          if (i % 8 === 0) {
            // we will push data to object
            ourForecastObject.push({
              date: formatDate(forecastData.list[i].dt_txt.split(" ")[0]),
              icon: forecastData.list[i].weather[0].icon,
              iconAlt: forecastData.list[i].weather[0].description,
              temp: max.toFixed(0) + "/" + min.toFixed(0),
              humidity: forecastData.list[i].main.humidity,
              wind: forecastData.list[i].wind.speed,
            });
          }
        }
        // iterate throughout "ourForecastObject" to print the forcast to the webpage
        /* console.log(ourForecastObject);
        console.log("------------------------------------------------"); */
        resolve(ourForecastObject);
      })
      .catch(function (error) {
        reject("There is an error: " + error);
      });
  });
};

function formatDate(date) {
  var month = date.split("-")[1];
  var day = date.split("-")[2];
  // to get rid of the 0 in front of the month or day if it exists
  if (month.charAt(0) === "0") {
    month = month.slice(1);
  }
  if (day.charAt(0) === "0") {
    day = day.slice(1);
  }
  return [day, meses[month - 1]].join(" ");
}

function getDay(date) {
  const d = date.split(" ")[0];
  return d.split("-")[2];
}

const weatherIcon = (weatherData) => {
  return (
    "https://openweathermap.org/img/wn/" +
    weatherData.weather[0].icon +
    "@2x.png"
  );
};

module.exports = {
  getCurrentWeather,
  getForecast,
  weatherIcon,
};
