const getdrawingControl = (google) => {
  return new google.maps.drawing.DrawingManager({
    drawingMode: google.maps.drawing.OverlayType.MARKER,
    drawingControl: true,
    drawingControlOptions: {
      position: google.maps.ControlPosition.TOP_CENTER,
      drawingModes: [
        google.maps.drawing.OverlayType.MARKER,
        google.maps.drawing.OverlayType.CIRCLE,
        google.maps.drawing.OverlayType.POLYGON,
      ],
    },
    markerOptions: {
      type: "marker",
      icon: {
        url: "/img/google-map-marker.png",
        /* labelOrigin: new google.maps.Point(17, 62),
        anchor: new google.maps.Point(17, 62), */
        labelOrigin: new google.maps.Point(30, 70),
        size: new google.maps.Size(60, 60),
        anchor: new google.maps.Point(30, 60),
      },
      label: {
        text: "Marcador",
        color: "#ffffff",
        fontWeight: "bold",
      },
      clickable: true,
      editable: true,
      draggable: true,
    },
    circleOptions: {
      type: "circle",
      fillColor: "#f4f4f4",
      fillOpacity: 0.6,
      strokeWeight: 4,
      strokeColor: "#f4f4f4",
      clickable: true,
      editable: true,
      draggable: true,
      zIndex: 1,
    },
    polygonOptions: {
      type: "polygon",
      fillColor: "#f4f4f4",
      fillOpacity: 0.6,
      strokeWeight: 4,
      strokeColor: "#f4f4f4",
      clickable: true,
      editable: true,
      draggable: true,
      zIndex: 1,
    },
  });
};

function getMapOptions(device) {
  const cameraOptions = {
    disableDefaultUI: true,
    rotateControl: true,
    fullscreenControl: true,
    zoom: device.position.zoom,
    mapTypeId: "satellite",
    heading: device.position.rotation,
    tilt: device.position.tilt,
    center: device.position,
  };

  return {
    ...cameraOptions,
    mapId: process.env.VUE_APP_MAP_ID,
  };
}

const drawCenter = (device, google, map) => {
  return new google.maps.Marker({
    icon: {
      url: "/img/logo_60.png",
      labelOrigin: new google.maps.Point(25, 65),
      anchor: new google.maps.Point(25, 65),
    },
    label: {
      text: device.name,
      color: "#ffffff",
      fontWeight: "bold",
      fontSize: "16px",
    },
    draggable: true,
    position: device.position,
    map,
  });
};

const save_item = (item, device) => {
  return new Promise((resolve, reject) => {
    try {
      const user = JSON.parse(localStorage.getItem("user")) || {};
      let marker = markerAdapter(item);
      const newItem = {
        device: device.id,
        marker,
      };
      fetch(`${process.env.VUE_APP_AUTHDOMAIN}/api/markers`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-token": user.token,
        },
        body: JSON.stringify(newItem),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.ok) {
            resolve(newItem.marker);
          } else {
            reject(res);
          }
        });
    } catch (e) {
      reject({ ok: false, e });
    }
  });
};

const update_item = (item) => {
  return new Promise((resolve, reject) => {
    try {
      const user = JSON.parse(localStorage.getItem("user")) || {};
      let marker = markerAdapter(item);
      const newItem = {
        device: item.device,
        marker,
      };
      fetch(`${process.env.VUE_APP_AUTHDOMAIN}/api/markers/${item.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-token": user.token,
        },
        body: JSON.stringify(newItem),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.ok) {
            resolve(newItem.marker);
          } else {
            reject(res);
          }
        });
    } catch (e) {
      reject({ ok: false, e });
    }
  });
};

const markerAdapter = (item) => {
  let marker = {
    type: item.type,
    name: item.name,
    reg: item.reg,
    writeReg: item.writeReg,
    action: item.action,
    image: item.image,
    onColor: item.onColor,
    offColor: item.offColor,
    onClass: item.onClass,
    offClass: item.offClass,
    onText: item.onText,
    offText: item.offText,
    preText: item.preText,
    postText: item.postText,
    classes: item.classes,
  };
  switch (item.type) {
    case "label":
    case "marker":
      marker.icon = item.getIcon();
      marker.label = item.getLabel();
      marker.position = item.getPosition();
      break;
    case "pivot":
    case "circle":
      marker.center = {
        lat: item.getCenter().lat(),
        lng: item.getCenter().lng(),
      };
      marker.radius = item.getRadius();

      break;
    case "polygon":
      marker.path = (() => {
        let latLngs = [];
        item.getPath().forEach(function (latLng) {
          latLngs.push({ lat: latLng.lat(), lng: latLng.lng() });
        });
        return latLngs;
      })();
      break;
    case "rectangle":
      item.bounds = () => {
        let bounds = item.getBounds();
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();
        const north = ne.lat();
        const south = sw.lat();
        const east = ne.lng();
        const west = sw.lng();
        return {
          north: north,
          south: south,
          east: east,
          west: west,
        };
      };
      break;
  }
  return marker;
};

const delete_item = (id, callback) => {
  const user = JSON.parse(localStorage.getItem("user")) || {};
  fetch(`${process.env.VUE_APP_AUTHDOMAIN}/api/markers/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "x-token": user.token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.ok) {
        callback(null, res);
      } else {
        callback("error", null);
      }
    })
    .catch((error) => {
      callback(error, null);
    });
};

/* const drawItem = (item, google, map, callback) => {
  let m, c, p, pv, point_line, point, punta, centro;
  switch (item.type) {
    case "marker":
      m = new google.maps.Marker({
        icon: item.icon,
        label: item.label,
        position: item.position,
        map,
      });
      asignProps(m, item);
      callback(m);
      break;
    case "circle":
      c = new google.maps.Circle({
        strokeColor: item.offColor,
        strokeOpacity: 0.7,
        strokeWeight: 3,
        fillColor: item.offColor,
        fillOpacity: 0.35,
        center: item.center,
        radius: item.radius,
        map,
      });
      asignProps(c, item);
      callback(c);
      break;
    case "polygon":
      p = new google.maps.Polygon({
        strokeColor: item.offColor,
        strokeOpacity: 0.7,
        strokeWeight: 3,
        fillColor: item.offColor,
        fillOpacity: 0.35,
        path: item.path,
      });
      asignProps(p, item);
      p.setMap(map);
      callback(p);
      break;
    case "pivot":
      pv = new google.maps.Circle({
        strokeColor: item.offColor,
        strokeOpacity: 0.7,
        strokeWeight: 3,
        fillColor: item.offColor,
        fillOpacity: 0.35,
        center: item.center,
        radius: item.radius,
        map,
      });
      pv.setMap(map);
      asignProps(pv, item);
      point_line = google.maps.geometry.spherical.computeOffset(
        pv.getCenter(),
        pv.getRadius(),
        0
      );
      new google.maps.Polyline({
        path: [item.center, point_line],
        map,
      });
      point = google.maps.geometry.spherical.computeOffset(
        pv.getCenter(),
        pv.getRadius(),
        0
      );
      punta = new google.maps.Marker({
        icon: {
          url: "/img/google-map-marker.png",
          anchor: new google.maps.Point(30, 60),
          labelOrigin: new google.maps.Point(30, 70),
        },
        label: {
          text: " ",
          color: "#ffffff",
          fontWeight: "bold",
        },
        position: point,
        map,
      });
      asignProps(punta, item);
      centro = new google.maps.Marker({
        icon: {
          url: "/img/centro.png",
          anchor: new google.maps.Point(30, 60),
          labelOrigin: new google.maps.Point(30, 70),
        },
        label: {
          text: item.name,
          color: "#ffffff",
          fontWeight: "bold",
        },
        position: item.center,
        map,
      });
      asignProps(centro, item);
      callback(pv);
      break;
    default:
      callback(null);
  }
}; */

const drawItem = (item, google, map, callback) => {
  let m, c, p, pv, r, pvl, point_line /*, point, punta, centro */;
  switch (item.type) {
    case "marker":
      m = new google.maps.Marker({
        icon: item.icon,
        label: item.label,
        position: item.position,
        animation: google.maps.Animation.DROP,
        map,
      });
      asignProps(m, item);
      callback(m);
      break;
    case "circle":
      if (!["pivot"].includes(item.subType)) {
        c = new google.maps.Circle({
          strokeColor: item.offColor,
          strokeOpacity: 0.7,
          strokeWeight: 3,
          fillColor: item.offColor,
          fillOpacity: 0.35,
          center: item.center,
          radius: item.radius,
          map,
        });
        asignProps(c, item);
        callback(c);
      } else {
        pv = new google.maps.Circle({
          strokeColor: item.offColor,
          strokeOpacity: 0.7,
          strokeWeight: 4,
          fillColor: item.offColor,
          fillOpacity: 0.1,
          center: item.center,
          radius: item.radius,
          map,
        });
        pv.setMap(map);
        asignProps(pv, {...item, type: "circle", subType: "pivot"});
        callback(pv);
        point_line = google.maps.geometry.spherical.computeOffset(
          pv.getCenter(),
          pv.getRadius(),
          0
        );
        pvl = new google.maps.Polyline({
          path: [item.center, point_line],
          geodesic: true,
          strokeColor: "lightblue",
          strokeOpacity: 0.3,
          strokeWeight: 5,
          map,
        });

        asignProps(pvl, { ...item, type: "line", subType: "pivot-line" });
        callback(pvl);
      }

      break;
    case "polygon":
      p = new google.maps.Polygon({
        strokeColor: item.offColor,
        strokeOpacity: 0.7,
        strokeWeight: 4,
        fillColor: item.offColor,
        fillOpacity: 0.1,
        path: item.path,
      });
      asignProps(p, item);
      callback(p);
      p.setMap(map);
      break;
    case "rectangle":
      r = new google.maps.Rectangle({
        strokeColor: item.offColor,
        strokeOpacity: 0.7,
        strokeWeight: 4,
        fillColor: item.offColor,
        fillOpacity: 0.1,
        bounds: item.bounds,
        map,
      });
      asignProps(r, item);
      callback(r);
      break;
    default:
      callback(null);
  }
};

const drawRawItem = (item, google, map, editable) => {
  switch (item.type) {
    case "marker":
    case "label":
      return new google.maps.Marker({
        icon: item.icon,
        label: item.label,
        draggable: editable,
        editable: editable,
        position: item.position,
        map,
      });
    case "pivot":
    case "circle":
      return new google.maps.Circle({
        draggable: editable,
        editable: editable,
        center: item.center,
        radius: item.radius,
        map,
      });
    case "polygon":
      return new google.maps.Polygon({
        draggable: editable,
        editable: editable,
        path: item.path,
      }).setMap(map);
    case "rectangle":
      return new google.maps.Rectangle({
        draggable: editable,
        editable: editable,
        bounds: item.bounds,
        map,
      });
  }
  return null;
};

const asignProps = (item, props) => {
  Object.assign(item, {
    type: props.type,
    subType: props.subType,
    name: props.name,
    reg: props.reg,
    writeReg: props.writeReg,
    auxRegs: props.auxRegs,
    action: props.action,
    image: props.image,
    onColor: props.onColor,
    offColor: props.offColor,
    onClass: props.onClass,
    offClass: props.offClass,
    onText: props.onText,
    offText: props.offText,
    preText: props.preText,
    postText: props.postText,
    classes: props.classes,
  });
};

const getItems = async (device) => {
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const resp = await fetch(
    `${process.env.VUE_APP_AUTHDOMAIN}/api/markers/${device.id}`,
    {
      headers: {
        "Content-Type": "application/json",
        "x-token": user.token,
      },
    }
  );

  const { markers: items } = await resp.json();

  return items;
};

/* const updateItem = (item, google, getValue) => {
  let update = false;
  if (!item) return;
  if (!item.reg) return;
  const value = getValue(item.reg);

  if (item.value != value) {
    item.value = value;
    console.log("Updating by value");
    update = true;
  }

  let values = [];

  if (item.auxRegs) {
    const regs = item.auxRegs.split(".");
    regs.forEach((reg, index) => {
      
      const v = getValue(reg) || 0;
      if (item.type == "pivot") console.log(reg, v)
      if (!item.values) {
        item.values = [];
        update = true;
        console.log("Updating by values[]");
      }
      if (item.values[index] == undefined) {
        item.values[index] = v;
        console.log(`Updating by values[${index}]`);
        update = true;
      }
      if (item.values[index] != v) {
        item.values[index] = v;
        console.log(`Updating by values[${index}] difference`);
        update = true;
      }
      values.push(v);
    });
  }

  if (!update) return;

  item.values = values;
  const {
    name,
    classes,
    onColor,
    offColor,
    onClass,
    offClass,
    onText,
    offText,
    preText,
    postText,
  } = item;
  if (value) {
    if (item.type == "marker") {
      let label = item.getLabel();
      label.text = `${preText}${onText}${postText}` || name || label.text;
      const className = onClass || classes;
      item.setLabel({ text: label.text, className });
    } else if (item.type == "label") {
      let label = item.getLabel();
      label.text = `${preText}${value}${postText}` || name || label.text;
      const className = onClass || classes;
      item.setLabel({ text: label.text, className });
    } else if (item.type == "pivot") {
      try {
        item.gltfOn.scene.visible = true;
        item.gltfOff.scene.visible = false;
        item.setOptions({
          fillColor: "transparent",
          strokeColor: onColor,
        });
        item.gltfOff.scene.rotation.y = ((360-values[0] + 90)  * Math.PI) / 180;
        item.gltfOn.scene.rotation.y = ((360-values[0] + 90) * Math.PI) / 180;
      } catch (error) {
        console.log(error);
      }
    } else if (item.type == "pivot-line") {
      try {
        const radius = google.maps.geometry.spherical.computeDistanceBetween(
          {
            lat: item.getPath().getArray()[0].lat(),
            lng: item.getPath().getArray()[0].lng(),
          },
          {
            lat: item.getPath().getArray()[1].lat(),
            lng: item.getPath().getArray()[1].lng(),
          }
        );
        const point_line = google.maps.geometry.spherical.computeOffset(
          {
            lat: item.getPath().getArray()[0].lat(),
            lng: item.getPath().getArray()[0].lng(),
          },
          radius,
          values[0]
        );
        item.setPath([
          {
            lat: item.getPath().getArray()[0].lat(),
            lng: item.getPath().getArray()[0].lng(),
          },
          { lat: point_line.lat(), lng: point_line.lng() },
        ]);
        item.setOptions({
          strokeColor: "blue",
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      item.setOptions({
        fillColor: onColor,
        strokeColor: onColor,
      });
    }
  } else {
    if (item.type == "marker") {
      let label = item.getLabel();
      label.text = `${preText}${offText}${postText}` || name || label.text;
      label.className = offClass || label.className;
      item.setLabel(label);
    } else if (item.type == "label") {
      let label = item.getLabel();
      label.text = `${preText}${value}${postText}` || name || label.text;
      const className = offClass || classes;
      item.setLabel({ text: label.text, className });
    } else if (item.type == "pivot") {
      try {
        item.gltfOn.scene.visible = false;
        item.gltfOff.scene.visible = true;
        item.setOptions({
          fillColor: "transparent",
          strokeColor: offColor,
        });
        item.gltfOff.scene.rotation.y = ((360-values[0] + 90) * Math.PI) / 180;
        item.gltfOn.scene.rotation.y = ((360-values[0] + 90)  * Math.PI) / 180;
      } catch (error) {
        console.log(error);
      }
    } else if (item.type == "pivot-line") {
      try {
        const radius = google.maps.geometry.spherical.computeDistanceBetween(
          {
            lat: item.getPath().getArray()[0].lat(),
            lng: item.getPath().getArray()[0].lng(),
          },
          {
            lat: item.getPath().getArray()[1].lat(),
            lng: item.getPath().getArray()[1].lng(),
          }
        );
        const point_line = google.maps.geometry.spherical.computeOffset(
          {
            lat: item.getPath().getArray()[0].lat(),
            lng: item.getPath().getArray()[0].lng(),
          },
          radius,
          values[0]
        );
        item.setPath([
          {
            lat: item.getPath().getArray()[0].lat(),
            lng: item.getPath().getArray()[0].lng(),
          },
          { lat: point_line.lat(), lng: point_line.lng() },
        ]);
        item.setOptions({
          strokeColor: offColor,
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      item.setOptions({
        fillColor: offColor,
        strokeColor: offColor,
      });
    }
  }
  item.value = value;
  item.values = values;
}; */

const updateItem = (item, google, getValue) => {
  let update = false;
  if (!item) return;
  if (!item.reg) return;
  const value = getValue(item.reg);

  if (item.value != value) {
    //console.log("Updating by value");
    update = true;
  }

  let values = [];

  if (item.auxRegs) {
    const regs = item.auxRegs.split(".");
    regs.forEach((reg, index) => {
      const v = getValue(reg) || 0;
      if (!item.values) {
        item.values = [];
        update = true;
        //console.log("Updating by values[]");
      }
      if (item.values[index] == undefined) {
        item.values[index] = v;
        //console.log(`Updating by values[${index}]`);
        update = true;
      }
      if (item.values[index] != v) {
        //item.values[index] = v;
        //console.log(`Updating by values[${index}] difference`);
        update = true;
      }
      values.push(v);
    });
  }
  //if (item.subType == "pivot") update = true;
  if (!update) return;

  item.values = values;
  const {
    name,
    classes,
    onColor,
    offColor,
    onClass,
    offClass,
    onText,
    offText,
    preText,
    postText,
  } = item;

  if (item.subType == "marker") {
    let label = item.getLabel();
    label.text =
      `${preText}${value ? onText : offText}${postText}` || name || label.text;
    const className = value ? onClass : offClass || classes;
    item.setLabel({ text: label.text, className });
    item.value = value;
  } else if (item.subType == "label") {
    let label = item.getLabel();
    label.text = `${preText}${value}${postText}` || name || label.text;
    const className = classes;
    item.setLabel({ text: label.text, className });
    item.value = value;
  } else if (item.subType == "pivot") {
    try {
      item.gltfOn.scene.visible = value ? true : false;
      item.gltfOff.scene.visible = value ? false : true;
      item.setOptions({
        fillColor: "transparent",
        strokeColor: value ? onColor : offColor,
      });
      item.gltfOff.scene.rotation.y = ((360 - values[0] + 90) * Math.PI) / 180;
      item.gltfOn.scene.rotation.y = ((360 - values[0] + 90) * Math.PI) / 180;
      item.values = values;
      item.value = value;
    } catch (error) {
      console.log(error);
    }
  } else if (item.subType == "pivot-line") {
    try {
      const radius = google.maps.geometry.spherical.computeDistanceBetween(
        {
          lat: item.getPath().getArray()[0].lat(),
          lng: item.getPath().getArray()[0].lng(),
        },
        {
          lat: item.getPath().getArray()[1].lat(),
          lng: item.getPath().getArray()[1].lng(),
        }
      );
      const point_line = google.maps.geometry.spherical.computeOffset(
        {
          lat: item.getPath().getArray()[0].lat(),
          lng: item.getPath().getArray()[0].lng(),
        },
        radius,
        values[0]
      );
      item.setPath([
        {
          lat: item.getPath().getArray()[0].lat(),
          lng: item.getPath().getArray()[0].lng(),
        },
        { lat: point_line.lat(), lng: point_line.lng() },
      ]);
      item.setOptions({
        strokeColor: "blue",
      });
    } catch (error) {
      console.log(error);
    }
  } else {
    item.setOptions({
      fillColor: value ? onColor : offColor,
      strokeColor: value ? onColor : offColor,
    });
    item.value = value;
  }
  
};

const loadGLTF = (items, loader, scene, google, device) => {
  items.forEach((item) => {
    if (!["pivot"].includes(item.subType)) return;
    if (item.gltfOn) scene.remove(scene.getObjectByName("PivotOn"));

    let altura = google.maps.geometry.spherical.computeDistanceBetween(
      new google.maps.LatLng(device.position.lat, item.center.lng()),
      { lat: item.center.lat(), lng: item.center.lng() }
    );
    if (item.center.lat() < device.position.lat) {
      altura *= -1;
    }

    let anchura = google.maps.geometry.spherical.computeDistanceBetween(
      new google.maps.LatLng(item.center.lat(), device.position.lng),
      { lat: item.center.lat(), lng: item.center.lng() }
    );
    if (item.center.lng() < device.position.lng) {
      anchura *= -1;
    }
    loader.load("assets/3d/pivot-v.glb", (gltf) => {
      gltf.scene.scale.set(item.radius / 200, 8, 8);
      gltf.scene.rotation.x = (90 * Math.PI) / 180; // rotations are in radians
      gltf.scene.rotation.y = (90 * Math.PI) / 180;
      gltf.scene.position.y = altura;
      gltf.scene.position.x = anchura;
      gltf.scene.name = "PivotOn";
      gltf.scene.visible = false;
      scene.add(gltf.scene);
      item.gltfOn = gltf;
    });
    if (item.gltfOff) scene.remove(scene.getObjectByName("PivotOff"));
    loader.load("assets/3d/pivot-r.glb", (gltf) => {
      gltf.scene.scale.set(item.radius / 200, 8, 8);
      gltf.scene.rotation.x = (90 * Math.PI) / 180; // rotations are in radians
      gltf.scene.rotation.y = (90 * Math.PI) / 180;
      gltf.scene.position.y = altura;
      gltf.scene.position.x = anchura;
      gltf.scene.name = "PivotOff";
      scene.add(gltf.scene);
      item.gltfOff = gltf;
    });
  });
};

const initWebglOverlayView = (
  items,
  device,
  map,
  google,
  THREE,
  GLTFLoader
) => {
  const webglOverlayView = new google.maps.WebglOverlayView();
  let loader, renderer;
  const scene = new THREE.Scene();
  const camera = new THREE.PerspectiveCamera();
  webglOverlayView.onAdd = () => {
    // set up the scene
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.75); // soft white light
    scene.add(ambientLight);
    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.25);
    directionalLight.position.set(0.5, -1, 0.5);
    scene.add(directionalLight);

    // load the model
    loader = new GLTFLoader();
    loadGLTF(items, loader, scene, google, device);
  };

  webglOverlayView.onContextRestored = (gl) => {
    // create the three.js renderer, using the
    // maps's WebGL rendering context.
    renderer = new THREE.WebGLRenderer({
      canvas: gl.canvas,
      context: gl,
      ...gl.getContextAttributes(),
    });
    renderer.autoClear = false;

    // wait to move the camera until the 3D model loads
    loader.manager.onLoad = () => {
      renderer.setAnimationLoop(() => {});
    };
  };

  webglOverlayView.onDraw = (gl, coordinateTransformer) => {
    // update camera matrix to ensure the model is georeferenced correctly on the map

    const center = {
      lat: device.position.lat,
      lng: device.position.lng,
    };
    const matrix = coordinateTransformer.fromLatLngAltitude({...center, altitude:0});
    camera.projectionMatrix = new THREE.Matrix4().fromArray(matrix);

    webglOverlayView.requestRedraw();
    renderer.render(scene, camera);

    // always reset the GL state
    renderer.resetState();
  };
  webglOverlayView.setMap(map);
  return { webglOverlayView, scene, camera, loader, renderer };
};

module.exports = {
  getdrawingControl,
  getMapOptions,
  save_item,
  update_item,
  delete_item,
  drawItem,
  drawCenter,
  getItems,
  markerAdapter,
  updateItem,
  loadGLTF,
  initWebglOverlayView,
  drawRawItem,
};
