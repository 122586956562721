const resizeImage = (device) => {
  const w = 300;
  const h = 188;
  if (!device) return "/assets/img/gallery/device-thumb.jpg";
  if (!device.img.includes("device-thumb")) {
    let arrImg = device.img.split("/");
    if (arrImg.length < 4) return device.img;
    let deviceType = `w_${w},h_${h},c_thumb,g_faces`;
    arrImg.splice(arrImg.length - 2, 0, deviceType);
    return arrImg.join("/");
  } else {
    return device.img;
  }
};

module.exports = {
    resizeImage,
}