class Memoria {
    constructor() {
        this.r = new DataView(new ArrayBuffer(32766));
        this.raux = new DataView(new ArrayBuffer(16));
        this.c = new Array(8192).fill(0);
    }

    bit_test(num, bit) {
        return (num >> bit) % 2 != 0;
    }
    bit_set(num, bit) {
        return num | (1 << bit);
    } 
    bit_clear(num, bit) {
        return num & ~(1 << bit);
    }
    bit_write(num, bit, value) {
        return value ? this.bit_set(num, bit) : this.bit_clear(num, bit);
    }
    bit_toggle(num, bit) {
        return this.bit_test(num, bit) ? this.bit_clear(num, bit) : this.bit_set(num, bit);
    }
    getBit(reg) {
        return this.c[reg];
    }
    getInt(reg) {
        return this.r.getInt16(reg * 2, true);
    }
    getUint(reg) {
        return this.r.getUint16(reg * 2, true);
    }
    getLong(reg) {
        return this.r.getInt32(reg * 2, true);
    }
    getUlong(reg) {
        return this.r.getUint32(reg * 2, true);
    }
    getFloat(reg) {
        return this.r.getFloat32(reg * 2, true);
    }
    setBit(reg) {
        //this.c[reg] = 1;
        return { trans: 5, offset: reg, values: 1 };
    }
    resetBit(reg) {
        //this.c[reg] = 0;
        return { trans: 5, offset: reg, values: 0 };
    }
    writeBit(reg, value) {
        //this.c[reg] = 0;
        return { trans: 5, offset: reg, values: value };
    }
    toggleBit(reg) {
        //this.c[reg] = this.c[reg] ? 0 : 1;
        return { trans: 5, offset: reg, values: this.c[reg] };
    }
    setMultipleBits(reg, values) {
        for (var i = 0; i < values.length; i++) this.c[+reg + i] = values[i];
        return {
            trans: 15,
            offset: reg,
            len: values.length,
            values: values,
        };
    }
    setInt(reg, value) {
        //this.raws.setInt16(reg * 2, value, true);
        return { trans: 6, offset: reg, len: 1, values: value };
    }
    setUint(reg, value) {
        //this.r.setUint16(reg * 2, value, true);
        return { trans: 6, offset: reg, len: 1, values: value };
    }
    setLong(reg, value) {
        this.raux.setInt32(0, value);
        var command = {
            trans: 16,
            offset: reg,
            len: 2,
            values: [this.raux.getInt16( 2), this.raux.getInt16(0)],
        };
        //this.r.setInt32(reg * 2, value, true);
        return command;
    }
    setULong(reg, value) {
        this.raux.setUint32(0, value);
        var command = {
            trans: 16,
            offset: reg,
            len: 2,
            values: [this.raux.getInt16(2), this.raux.getInt16(0)],
        };
        //this.r.setUint32(reg * 2, value, true);
        return command;
    }
    setFloat(reg, value) {
        this.raux.setFloat32(0, value);
        var command = {
            trans: 16,
            offset: reg,
            len: 2,
            values: [this.raux.getInt16(2), this.raux.getInt16(0)], 
        };
        //this.r.setFloat32(reg * 2, value, true);
        return command;
    }
}

module.exports = Memoria
