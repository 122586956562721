<template>
  <div>
    <!-- BEGIN page-header -->
    <h1 class="page-header">Widgets</h1>
    <!-- END page-header -->
    <!-- BEGIN panel -->
    <b-tabs
      nav-class="nav-tabs-inverse"
      nav-wrapper-class="nav nav-tabs nav-tabs-inverse"
    >
      <div class="tab-content rounded-bottom p-3 panel rounded-0">
        <!-- BEGIN tab-pane -->
        <b-tab active>
          <template slot="title">
            <i class="fa fa-fw fa-lg fa-arrow-down-short-wide"></i>
            <span class="d-none d-xl-inline"> Rows</span>
          </template>
          <span
            class="badge bg-success fs-10px nuevo float-end p-2"
            @click="openModalNew('row')"
            >Nuevo</span
          >
          <h4 class="mb-1"><b>Rows</b></h4>
          <p class="mb-3">Filas mostradas</p>
          <!-- BEGIN table-responsive -->
          <div class="table-responsive">
            <table
              class="table table-striped mb-0 align-middle table-condensed"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nombre</th>
                  <th>Tipo</th>
                  <th>Título</th>
                  <th>Descripción</th>
                  <th width="1%"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in itemsRows"
                  :key="'itemRow-' + index"
                >
                  <td>
                    {{ index + 1 }}
                  </td>
                  <td>{{ item.widget.name }}</td>
                  <td>{{ item.widget.type }}</td>
                  <td>{{ item.widget.title }}</td>
                  <td>{{ item.widget.description }}</td>
                  <td>
                    <div class="d-flex d-inline-flex">
                      <div
                        class="
                          widget-icon
                          rounded
                          bg-success
                          me-5px
                          mb-5px
                          text-white
                        "
                        @click="saveItem(index, itemsRows)"
                      >
                        <i class="fa fa-save"></i>
                      </div>
                      <div
                        class="
                          widget-icon
                          rounded
                          bg-primary
                          me-5px
                          mb-5px
                          text-white
                        "
                        @click="showModal(index, itemsRows, 'row')"
                      >
                        <i class="fa fa-edit"></i>
                      </div>
                      <b-modal
                        :id="`modal-edit-row-${index}`"
                        :title="item.widget.title"
                        @ok="editar(index, itemsRows)"
                      >
                        <textarea
                          cols="50"
                          rows="10"
                          v-model="itemsRows[index].prettyJSON"
                        ></textarea>
                      </b-modal>
                      <div
                        class="
                          widget-icon
                          rounded
                          bg-danger
                          me-5px
                          mb-5px
                          text-white
                        "
                        @click="borrar(index, itemsRows)"
                      >
                        <i class="fa fa-trash"></i>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
        <!-- END tab-pane -->
        <!-- BEGIN tab-pane -->
        <b-tab>
          <template slot="title">
            <i class="fa fa-fw fa-lg fa-box"></i>
            <span class="d-none d-xl-inline"> Widgets</span>
          </template>
          <span
            class="badge bg-success fs-10px nuevo float-end p-2"
            @click="openModalNew('widget')"
            >Nuevo</span
          >
          <h4 class="mb-1"><b>Widgets</b></h4>
          <p class="mb-3">Widgets dibujados en el dashboard</p>
          <!-- BEGIN table-responsive -->
          <div class="table-responsive">
            <table
              class="table table-striped mb-0 align-middle table-condensed"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nombre</th>
                  <th>Tipo</th>
                  <th>Contenedor</th>
                  <th>Título</th>
                  <th>Descripción</th>
                  <th width="1%"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in itemsWidgets"
                  :key="'itemsWidget-' + index"
                >
                  <td>
                    {{ index + 1 }}
                  </td>
                  <td>{{ item.widget.name }}</td>
                  <td>{{ item.widget.type }}</td>
                  <td>{{ item.widget.container }}</td>
                  <td>{{ item.widget.title }}</td>
                  <td>{{ item.widget.description }}</td>
                  <td>
                    <div class="d-flex d-inline-flex">
                      <div
                        class="
                          widget-icon
                          rounded
                          bg-success
                          me-5px
                          mb-5px
                          text-white
                        "
                        @click="saveItem(index, itemsWidgets)"
                      >
                        <i class="fa fa-save"></i>
                      </div>
                      <div
                        class="
                          widget-icon
                          rounded
                          bg-primary
                          me-5px
                          mb-5px
                          text-white
                        "
                        @click="showModal(index, itemsWidgets, 'widget')"
                      >
                        <i class="fa fa-edit"></i>
                      </div>
                      <b-modal
                        :id="`modal-edit-widget-${index}`"
                        :title="item.widget.title"
                        @ok="editar(index, itemsWidgets)"
                      >
                        <textarea
                          cols="50"
                          rows="10"
                          v-model="itemsWidgets[index].prettyJSON"
                        ></textarea>
                      </b-modal>
                      <div
                        class="
                          widget-icon
                          rounded
                          bg-danger
                          me-5px
                          mb-5px
                          text-white
                        "
                        @click="borrar(index, itemsWidgets)"
                      >
                        <i class="fa fa-trash"></i>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
        <!-- END tab-pane -->
      </div>
    </b-tabs>

    <b-modal id="modal-edit-new" title="Nuevo widget" @ok="agregar">
      <textarea
        ref="textarea"
        cols="50"
        rows="10"
        v-model="editedItem.prettyJSON"
      ></textarea>
    </b-modal>
    <!-- END table-responsive -->

    <notifications
      group="bottom-center"
      position="bottom center"
      :speed="500"
    />
  </div>
</template>

<script>
import { gmapApi } from "vue2-google-maps";
import { mapGetters } from "vuex";
import { getItems, newItem, updateItem, deleteItem } from "@/helpers/widgets";

export default {
  name: "widgetControls",
  data() {
    return {
      items: [],
      map: null,
      selected: -1,
      editedItem: {
        prettyJSON: "",
        widget: null,
      },
      control: {
        name: "widget",
        type: "",
        container: "",
        title: "",
        description: "",
        children: [],
      },
    };
  },
  created() {},
  mounted() {
    localStorage.setItem("screen", "widget-controls");
    if (this.currentDevice) this.loadItems();
  },
  methods: {
    getItems,
    newItem,
    updateItem,
    deleteItem,

    editar(index, items) {
      try {
        items[index].widget = JSON.parse(items[index].prettyJSON);
        this.$notify({
          group: "bottom-center",
          title: "¡Exito!",
          text: "Propiedades modificadas",
          type: "success",
        });
      } catch (error) {
        console.error(error);
        this.$notify({
          group: "bottom-center",
          title: "Error",
          message: "El formato del marcador no es correcto",
          type: "danger",
        });
      }
    },
    agregar() {
      try {
        this.editedItem.widget = JSON.parse(this.editedItem.prettyJSON);
        this.newItem(this.editedItem)
          .then((item) => {
            this.items.unshift(item.widget);
            this.$notify({
              group: "bottom-center",
              title: `¡Operación exitosa!`,
              text: `El elemento ha sido agregado`,
              type: "success",
            });
            console.log(item);
          })
          .catch((err) => {
            console.log(err);
            this.$notify({
              group: "bottom-center",
              title: `¡Algo fué mal en la operación!`,
              text: `El elemento no ha sido agregado`,
              type: "danger",
            });
          });
      } catch (error) {
        console.error(error);
      }
    },
    setPrettyJSON() {
      this.$refs.textarea.value = JSON.stringify(
        this.editedItem.widget,
        null,
        4
      );
    },
    showModal(index, items, type) {
      this.selected = index;
      this.editedItem = items[index];
      this.$bvModal.show(`modal-edit-${type}-${index}`);
    },
    openModalNew(type) {
      this.editedItem = {
        device: this.currentDevice.id,
        widget: { ...this.control, type },
      };
      console.log(this.editedItem);
      this.$bvModal.show("modal-edit-new");
      setTimeout(this.setPrettyJSON, 1000);
      
    },
    loadItems() {
      this.getItems(this.currentDevice)
        .then((data) => {
          if (data.ok) {
            this.items = data.widgets.map((widget) => {
              return {
                ...widget,
                prettyJSON: JSON.stringify(
                  { ...this.control, ...widget.widget },
                  null,
                  4
                ),
              };
            });
          }
        })
        .catch(console.error);
    },
    saveItem(index, items) {
      try {
        this.updateItem(items[index])
          .then((item) => {
            this.$notify({
              group: "bottom-center",
              title: `¡Operación exitosa!`,
              text: `El elemento ha sido modificado`,
              type: "success",
            });
            console.log(item);
          })
          .catch((err) => {
            console.log(err);
            this.$notify({
              group: "bottom-center",
              title: `¡Algo fué mal en la operación!`,
              text: `El elemento no ha sido modificado`,
              type: "danger",
            });
          });
      } catch (error) {
        console.error(error);
      }
    },
    borrar(index, items) {
      this.$bvModal
        .msgBoxConfirm("¿Borrar el marcador?")
        .then((value) => {
          if (value) {
            this.deleteItem(items[index])
              .then((data) => {
                if (data.ok) {
                  this.loadItems();
                  this.$notify({
                    group: "bottom-center",
                    title: `¡Operación exitosa!`,
                    text: `El elemento ha sido borrado`,
                    type: "success",
                  });
                }
              })
              .catch((error) => {
                console.error(error);
                this.$notify({
                  group: "bottom-center",
                  title: `¡Algo fué mal en la operación!`,
                  text: `El elemento no ha sido borrado`,
                  type: "danger",
                });
              });
          }
        })
        .catch(console.error);
    },
  },
  computed: {
    ...mapGetters("devices", ["currentDevice", "getValue", "bufferChanged"]),
    ...mapGetters("auth", ["currentUser"]),
    itemsRows() {
      return this.items.filter(
        (item) => item.widget.type === "row"
      );
    },
    itemsWidgets() {
      return this.items.filter(
        (item) => item.widget.type !== "row"
      );
    },
    finca: function () {
      if (!this.currentDevice) return "";
      return this.currentDevice.name
        ? this.currentDevice.name
        : '<i class="fas fa-spinner fa-spin"></i>';
    },
    google: gmapApi,
  },
  watch: {
    currentDevice: function () {
      if (this.currentDevice) this.loadItems();
    },
  },
};
</script>

<style scoped>
.widget-icon,
.nuevo {
  cursor: pointer;
}
textarea {
  border: 1px inset #ccc;
  background-color: white;
  font: small courier, monospace black;
  width: 100%;
  height: 300px; /* or whatever measurements you want */
  overflow: auto; /* in most browsers, this will results in a scrollbar when there's more content than can fit in the dimensions defined above */
}
</style>
