var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('h1',{staticClass:"page-header"},[_vm._v(" Tabla de variables "),_c('small',[_vm._v(_vm._s(_vm.finca))])]),_c('panel',{attrs:{"bodyClass":"p-0","noButton":"true"}},[_c('template',{slot:"header"},[_c('h4',{staticClass:"panel-title"},[_vm._v("Lista de variables")])]),_c('span',{staticClass:"fa-stack fa-2x text-primary m-2"},[_c('i',{staticClass:"fa fa-file me-2",on:{"click":_vm.insertar}}),_c('i',{staticClass:"fa fa-save",on:{"click":_vm.guardarTodo}})]),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"lineNumbers":true,"selectOptions":{
        enabled: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
        selectionText: 'filas seleccionadas',
        clearSelectionText: 'limpiar',
      }},on:{"on-selected-rows-change":_vm.selectionChanged},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(
            ['name', 'description', 'address'].includes(props.column.field)
          )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.rows[props.index][props.column.field]),expression:"rows[props.index][props.column.field]"}],staticClass:"form-control form-control-sm",attrs:{"type":"text"},domProps:{"value":(_vm.rows[props.index][props.column.field])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.rows[props.index], props.column.field, $event.target.value)}}}):(
            ['offset', 'gain', 'DB_Interval'].includes(props.column.field)
          )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.rows[props.index][props.column.field]),expression:"rows[props.index][props.column.field]"}],staticClass:"form-control form-control-sm",attrs:{"type":"number","step":"0.01"},domProps:{"value":(_vm.rows[props.index][props.column.field])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.rows[props.index], props.column.field, $event.target.value)}}}):(['dataBase'].includes(props.column.field))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.rows[props.index][props.column.field]),expression:"rows[props.index][props.column.field]"}],staticClass:"form-check-input",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.rows[props.index][props.column.field])?_vm._i(_vm.rows[props.index][props.column.field],null)>-1:(_vm.rows[props.index][props.column.field])},on:{"change":function($event){var $$a=_vm.rows[props.index][props.column.field],$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.rows[props.index], props.column.field, $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.rows[props.index], props.column.field, $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.rows[props.index], props.column.field, $$c)}}}}):(['access'].includes(props.column.field))?_c('b-form-select',{staticClass:"form-select form-select-sm",attrs:{"options":[
            { value: 'read/write', text: 'read/write' },
            { value: 'read', text: 'read' },
            { value: 'write', text: 'write' } ]},model:{value:(_vm.rows[props.index][props.column.field]),callback:function ($$v) {_vm.$set(_vm.rows[props.index], props.column.field, $$v)},expression:"rows[props.index][props.column.field]"}}):(['dataType'].includes(props.column.field))?_c('b-form-select',{staticClass:"form-select form-select-sm",attrs:{"options":[
            { value: 'int', text: 'int' },
            { value: 'uint', text: 'uint' },
            { value: 'long', text: 'long' },
            { value: 'ulong', text: 'ulong' },
            { value: 'float', text: 'float' },
            { value: 'bool', text: 'bool' } ]},model:{value:(_vm.rows[props.index][props.column.field]),callback:function ($$v) {_vm.$set(_vm.rows[props.index], props.column.field, $$v)},expression:"rows[props.index][props.column.field]"}}):(['value'].includes(props.column.field))?_c('input',{ref:'value'+props.index,staticClass:"form-control form-control-sm",attrs:{"type":"number","step":"0.1"}}):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_vm._v(" > "),_c('div',{attrs:{"slot":"selected-row-actions"},slot:"selected-row-actions"},[_c('button',{staticClass:"btn btn-danger me-2",on:{"click":_vm.borrar}},[_c('i',{staticClass:"fa fa-trash"})]),_c('button',{staticClass:"btn btn-primary me-2",on:{"click":_vm.copiar}},[_c('i',{staticClass:"fa fa-copy"})]),_c('button',{staticClass:"btn btn-primary me-2",on:{"click":_vm.pegar}},[_c('i',{staticClass:"fa fa-paste"})]),_c('button',{staticClass:"btn btn-success",on:{"click":_vm.guardar}},[_c('i',{staticClass:"fa fa-save"})])])])],2)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ol',{staticClass:"breadcrumb float-xl-end"},[_c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("Inicio")])]),_c('li',{staticClass:"breadcrumb-item active"},[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("variables")])])])}]

export { render, staticRenderFns }