<template>
  <button :class="'btn btn-block btn-' + className + ' me-1'" @click="clicked">
    {{ text }}
  </button>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "PushButton",
  props: {
    texts: {
      type: Object,
      default: () => ({
        0: "Off",
        1: "On",
      }),
      required: true,
    },
    classNames: {
      type: Object,
      default: () => ({
        0: "default",
        1: "primary",
      }),
      required: true,
    },
    tags: {
      type: Object,
      default: () => ({
        0: null,
        1: null,
      }),
      required: true,
    },
    actions: {
      type: Object,
      default: function () {
        return {
          0: "reset",
          1: "set",
        };
      },
    },
  },
  data() {
    return {
      transactions: 0,
      state: 0,
    };
  },
  computed: {
    ...mapGetters("devices", ["currentDevice", "getValue", "bufferChanged"]),
    text: function () {
      return this.texts[this.state];
    },
    className: function () {
      return this.classNames[this.state];
    },
  },
  methods: {
    ...mapActions("devices", ["setValue"]),
    clicked() {
      const action = this.actions[this.state];
      const register = this.tags[this.state];
      console.log(action, register);
      switch (action) {
        case "toggle":
          this.setValue({ register, values: this.state ? 0 : 1 });
          break;
        case "set":
          this.setValue({ register, values: 1 });
          break;
        case "reset":
          this.setValue({ register, values: 0 });
          break;
      }
    },
  },
  watch: {
    bufferChanged(nv) {
      this.state = this.getValue(this.tags[0]);
      this.transactions = nv;
    },
  },
};
</script>
