<script>
import Login from "../pages/Login.vue";
import ConfirmMail from "../pages/ConfirmMail.vue";
import ForgotPassword from "../pages/ForgotPassword.vue";
import Recovery from "../pages/Recovery.vue";
import Dashboard from "../pages/Dashboard.vue";
import Register from "../pages/Register.vue";
import Devices from "../pages/Devices.vue";
import Map from "../pages/Map.vue";
import MapControls from "../pages/MapControls.vue";
import WidgetControls from "../pages/WidgetControls.vue";
import Mapbox from "../pages/Mapbox.vue";
import Historical from "../pages/Historical.vue";
import Variables from "../pages/Variables.vue";

const routes = [
  { path: "*", redirect: "/login" },
  { path: "/", redirect: "/login" },
  { path: "/register", component: Register, name: "register" },
  { path: "/login", component: Login, name: "login" },
  { path: "/confirm-mail", component: ConfirmMail, name: "confirm-mail" },
  { path: "/forgot-password", component: ForgotPassword, name: "forgot-password" },
  { path: "/recovery", component: Recovery, name: "recovery" },
  {
    path: "/dashboard",
    component: Dashboard,
    name: "dashboard",
    meta: { requiresAuth: true, requiresAdmin: false },
  },
  {
    path: "/widget-controls",
    component: WidgetControls,
    name: "widget-controls",
    meta: { requiresAuth: true, requiresAdmin: false },
  },
  {
    path: "/devices",
    component: Devices,
    name: "devices",
    meta: { requiresAuth: true, requiresAdmin: false },
  },
  {
    path: "/map",
    component: Map,
    name: "map",
    meta: { requiresAuth: true, requiresAdmin: false },
  },
  {
    path: "/map-controls",
    component: MapControls,
    name: "map-controls",
    meta: { requiresAuth: true, requiresAdmin: false },
  },
  {
    path: "/mapbox",
    component: Mapbox,
    name: "mapbox",
    meta: { requiresAuth: true, requiresAdmin: false },
  },
  {
    path: "/historical",
    component: Historical,
    name: "historical",
    meta: { requiresAuth: true, requiresAdmin: false },
  },
  {
    path: "/variables",
    component: Variables,
    name: "variables",
    meta: { requiresAuth: true, requiresAdmin: true },
  },
];

export default routes;
</script>
