<template>
  <div class="col-xl-3 col-md-6">
    <div :class="'widget widget-stats ' + data.color || 'bg-teal'">
      <div class="stats-icon stats-icon-lg">
        <i :class="data.icon || 'fa fa-gauge-simple fa-fw'"></i>
      </div>
      <div class="stats-content">
        <div class="stats-title">{{ data.title || "Indicador" }}</div>
        <div class="stats-number">{{ parseFloat(value).toFixed(data.dp) }}{{ data.unit || "" }}</div>
        <div class="stats-progress progress" v-show="data.progress">
          <div
            class="progress-bar"
            :style="'width: ' + data.progress + '%'"
          ></div>
        </div>
        <div class="stats-desc">{{ data.description || "Descripción" }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Indicator",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      value: 0,
    };
  },
  computed: {
    ...mapGetters("devices", ["getValue", "bufferChanged"]),
  },
  watch: {
    bufferChanged(nv) {
      this.value = this.getValue(this.data.tag);
      this.transactions = nv;
    },
  },
};
</script>

<style></style>
