import Vue from "vue";
import VueX from "vuex";
import routes from "./config/AppRoutes";

// plugins
import VueRouter from "vue-router";
import VueBootstrap from "bootstrap-vue";
import VueNVD3 from "vue-nvd3";
import VueInsProgressBar from "vue-ins-progress-bar";
import VueEventCalendar from "vue-event-calendar";
import VueSparkline from "vue-sparklines";
import * as VueGoogleMaps from "vue2-google-maps";
import Vueditor from "@agametov/vueditor";
import VueHljs from "vue-hljs";
import hljs from "highlight.js";
import VueSweetalert2 from "vue-sweetalert2";
import VueNotification from "vue-notification";
import VuePanel from "./plugins/panel/";
import VueIconPushButton from "./plugins/IconPushButton";
import VueSelectButton from "./plugins/SelectButton/"
import VuePushButton from "./plugins/PushButton/"
import VueDateTimePicker from "vue-bootstrap-datetimepicker";
import VueSelect from "vue-select";
import VueDatepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js";
import VueMaskedInput from "vue-maskedinput";
import VueInputTag from "vue-input-tag";
import VueSlider from "vue-slider-component";
import VueGoodTable from "vue-good-table";
import VueCountdown from "@chenfengyuan/vue-countdown";
import VueColorpicker from "vue-pop-colorpicker";
import VueCustomScrollbar from "vue-custom-scrollbar";
import VueApexCharts from "vue-apexcharts";
import DateRangePicker from "vue2-daterange-picker";

// plugins css
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "nvd3/build/nv.d3.min.css";
import "vue-event-calendar/dist/style.css";
import "vue-hljs/dist/style.css";
import "@agametov/vueditor/dist/style/vueditor.min.css";
import "vue-bootstrap-datetimepicker/dist/bootstrap-datetimepicker.css";
import "simple-line-icons/css/simple-line-icons.css";
import "flag-icon-css/css/flag-icons.min.css";
import "ionicons/dist/css/ionicons.min.css";
import "vue-good-table/dist/vue-good-table.css";
import "vue-select/dist/vue-select.css";
import "vue-slider-component/theme/antd.css";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "vue-custom-scrollbar/dist/vueScrollbar.css";

// color admin css
import "./scss/vue.scss";
import "bootstrap-social/bootstrap-social.css";

// Store
import store from "./store";

import App from "./App.vue";

Vue.config.productionTip = false;

Vue.use(VueX);
Vue.use(VueRouter);
Vue.use(VueBootstrap);
Vue.use(VueNVD3);
Vue.use(VueEventCalendar, { locale: "en" });
Vue.use(VueSparkline);
Vue.use(Vueditor);
Vue.use(VueHljs, { hljs });
Vue.use(VueSweetalert2);
Vue.use(VueNotification);
Vue.use(VuePanel);
Vue.use(VueIconPushButton);
Vue.use(VuePushButton);
Vue.use(VueSelectButton);
Vue.use(VueDateTimePicker);
Vue.use(VueGoodTable);
Vue.use(VueColorpicker);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBhZUKOaT0ofOt6Gznj_34L_8Eir3fPLo8",
    libraries: "places,drawing,visualization,geometry", // This is required if you use the Autocomplete plugin
    //// If you want to set the version, you can do so:
    v: "beta",
  },
});
Vue.use(VueInsProgressBar, {
  position: "fixed",
  show: true,
  height: "3px",
});
Vue.component("v-select", VueSelect);
Vue.component("datepicker", VueDatepicker);
Vue.component("masked-input", VueMaskedInput);
Vue.component("input-tag", VueInputTag);
Vue.component("vue-slider", VueSlider);
Vue.component("vue-custom-scrollbar", VueCustomScrollbar);
Vue.component("apexchart", VueApexCharts);
Vue.component("date-range-picker", DateRangePicker);
Vue.component(VueCountdown.name, VueCountdown);

const router = new VueRouter({
  routes,
});

router.beforeEach(async (to, from, next) => {
  const publicPages = [
    "/register",
    "/forgot-password",
    "/email-verification",
    "/confirm-mail",
    "/recovery",
  ];
  const authpage = !publicPages.includes(to.path);
  if (!authpage) {
    return next();
  }
  if (to.matched.some((route) => route.meta.requiresAuth)) {
    if (!store.getters["auth/currentUser"]) {
      //we're getting 200 status code response here, so user is authorized
      //be sure that API you're consuming return correct status code when user is authorized
      return next({ name: "login" });
    } else {
      //we're getting anything but not 200 status code response here, so user is not authorized
      return next();
    }
  }
  if (to.path === "/login") {
    if (store.getters["auth/currentUser"]) {
      const screen = localStorage.getItem("screen") || "dashboard";
      return next({ name: screen });
    } else {
      const loggeduser = JSON.parse(localStorage.getItem("user") || false);
      if (loggeduser) {
        if (loggeduser.token) {
          const resp = await fetch(
            `${process.env.VUE_APP_AUTHDOMAIN}/api/auth/check`,
            {
              method: "get",
              headers: {
                "x-token": loggeduser.token,
              },
            }
          );
          const user = await resp.json();

          if (!user) {
            localStorage.removeItem("user");
            localStorage.removeItem("screen");
            return next();
          }
          if (!user.ok) {
            localStorage.removeItem("user");
            localStorage.removeItem("screen");
            return next();
          }
          
          const nUser = {
            id: user.uid,
            name: user.name,
            email: user.email,
            credentials: user.credentials,
            token: loggeduser.token,
          };
    
          store.dispatch("auth/setUser", user);
          const screen = localStorage.getItem("screen") || "dashboard";
          localStorage.setItem("user", JSON.stringify(nUser));
          return next({ name: screen });
        } else {
          return next();
        }
      } else {
        return next();
      }
    }
  }
});

new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount("#app");
